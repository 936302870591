import classNames from 'classnames'
import React from 'react'
import './double-bounce-spinner.scss'

type Props = {
  light?: boolean
  className?: string
}

export default function DoubleBounceSpinner({ light, className }: Props) {
  return (
    <div
      data-testid="spinner"
      className={classNames('double-bounce-spinner', className)}
    >
      <div
        className={classNames('double-bounce1', {
          light,
        })}
      />
      <div
        className={classNames('double-bounce2', {
          light,
        })}
      />
    </div>
  )
}
