import React, { PureComponent } from 'react'

import { i18n } from '~/i18n'
import {
  INVALID,
  REQUIRED,
  validateCurrentPassword,
  validatePassword,
} from '~/utils/validations'

import { CurrentPassword, NewPassword, Actions } from './components'

import './password.scss'

import type { ReactNode } from 'react'
import type { ErrorCode } from '~/utils/validations'

export type PasswordData = {
  password: string
  currentPassword: string
}

type PaswordErrors = {
  password?: string[]
  currentPassword?: string[]
}

type Props = {
  showCurrentPasswordField?: boolean
  showActions?: boolean
  displayRequirementsAbove?: boolean
  onSubmit?: (arg0: PasswordData) => void
  errors?: PaswordErrors
  onCancel?: () => void
  className?: string
  passwordInputOverride?: ReactNode
  passwordErrorOverride?: ReactNode
  label?: ReactNode
}

type State = {
  currentPassword: string
  password: string
  showNonLocalErrors: boolean
  localErrors: {
    currentPassword?: string[]
    password?: string[]
  }
}

class Password extends PureComponent<Props, State> {
  state = {
    currentPassword: '',
    password: '',
    showNonLocalErrors: false,
    localErrors: {
      currentPassword: [],
      password: [],
    },
  }

  static defaultProps = {
    errors: {
      currentPassword: [],
      password: [],
    },
    showCurrentPasswordField: true,
    showActions: true,
    vertical: false,
  }

  handleCurrentPasswordChange = ({
    // @ts-expect-error TS2339
    target: { value },
  }: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      currentPassword: value,
    })
  }

  handleNewPasswordChange = ({
    // @ts-expect-error TS2339
    target: { value },
  }: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      password: value,
    })
  }

  parsePasswordValidationErrors(errors?: { password: ErrorCode }) {
    if (!errors) return []

    switch (errors.password) {
      case REQUIRED:
        return [i18n.t('components.password.errors.new_blank')]

      case INVALID:
        return [i18n.t('components.password.errors.missing_requirements')]

      default:
        return []
    }
  }

  parseCurrentPasswordValidationErrors(errors?: {
    currentPassword: ErrorCode
  }) {
    if (!errors) return []

    switch (errors.currentPassword) {
      case REQUIRED:
        return [i18n.t('components.password.errors.current_blank')]

      default:
        return []
    }
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const {
      parsePasswordValidationErrors,
      parseCurrentPasswordValidationErrors,
    } = this
    const { currentPassword, password } = this.state
    const { showCurrentPasswordField } = this.props
    const currentPasswordValidationObj = validateCurrentPassword({
      currentPassword,
    })
    const passwordValidationObj = validatePassword({
      password,
    })
    const currentPasswordIsValid = !currentPasswordValidationObj
    const passwordIsValid = !passwordValidationObj

    this.setState({
      localErrors: {
        currentPassword: parseCurrentPasswordValidationErrors(
          currentPasswordValidationObj,
        ),
        password: parsePasswordValidationErrors(passwordValidationObj),
      },
    })

    if (
      passwordIsValid &&
      (currentPasswordIsValid || !showCurrentPasswordField)
    ) {
      this.setState({
        showNonLocalErrors: true,
      })

      this.props.onSubmit({
        currentPassword,
        password,
      })
    } else {
      this.setState({
        showNonLocalErrors: false,
      })
    }
  }

  patchNonLocalErrors = () => {
    const { localErrors } = this.state
    const { errors } = this.props
    return {
      currentPassword: [
        ...(localErrors.currentPassword || []),
        ...((errors && errors.currentPassword) || []),
      ],
      password: [
        ...(localErrors.password || []),
        ...((errors && errors.password) || []),
      ],
    }
  }

  render() {
    const { localErrors, showNonLocalErrors } = this.state

    const {
      showCurrentPasswordField,
      showActions,
      displayRequirementsAbove,
      onCancel,
      onSubmit,
      passwordInputOverride,
      passwordErrorOverride,
      label,
    } = this.props

    const {
      handleCurrentPasswordChange,
      handleNewPasswordChange,
      handleSubmit,
      patchNonLocalErrors,
    } = this

    const errors = showNonLocalErrors ? patchNonLocalErrors() : localErrors
    const currentPasswordHasErrors =
      errors.currentPassword && !!errors.currentPassword.length
    const passwordHasErrors = errors.password && !!errors.password.length
    const Container = onSubmit ? 'form' : 'div'

    return (
      <Container className="password-container" onSubmit={handleSubmit}>
        {showCurrentPasswordField && (
          <CurrentPassword
            {...{
              handleCurrentPasswordChange,
              currentPasswordHasErrors,
              errors,
            }}
          />
        )}
        <NewPassword
          {...{
            displayRequirementsAbove,
            passwordInputOverride,
            handleNewPasswordChange,
            passwordHasErrors,
            passwordErrorOverride,
            errors,
            label,
          }}
        />
        {showActions && onSubmit && <Actions onCancel={onCancel} />}
      </Container>
    )
  }
}

export default Password
