export type PendingProfileSharesModalUIActions =
  | {
      type: 'pendingProfileSharesModal/SHOW'
    }
  | {
      type: 'pendingProfileSharesModal/HIDE'
    }

export const SHOW_PENDING_PROFILE_SHARES_MODAL =
  'pendingProfileSharesModal/SHOW' as const
export function showPendingProfileSharesModal() {
  return {
    type: SHOW_PENDING_PROFILE_SHARES_MODAL,
  }
}

export const HIDE_PENDING_PROFILE_SHARES_MODAL =
  'pendingProfileSharesModal/HIDE' as const
export function hidePendingProfileSharesModal() {
  return {
    type: HIDE_PENDING_PROFILE_SHARES_MODAL,
  }
}
