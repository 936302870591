import { creditCardSerializer } from '../../serializers/credit-card'
import { authedFetch } from '../fetch'

import type { NewCreditCard } from '~/types'

const CC_PATH = '/api/v2/credit-cards'

export function requestCreateCreditCard(
  creditCard: NewCreditCard,
  adminActingAsProfileId?: string,
) {
  const opts = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify(
      creditCardSerializer(
        {
          creditCard,
        },
        adminActingAsProfileId,
      ),
    ),
  }
  return authedFetch(CC_PATH, opts)
}
