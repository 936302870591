import React, { ReactNode, useEffect, useState } from 'react'

import { LANGUAGE_PACK, LOCALE } from '~/config'
import { useTranslationPack } from '~/hooks/translation-pack'
import useFeatureFlags from '~/hooks/use-feature-flags'
import { i18n } from '~/i18n'

type Props = {
  children: ReactNode
}

function WindowConstantsTranslationsProvider({ children }: Props) {
  const [hasSetTranslations, setHasSetTranslations] = useState(false)

  useEffect(() => {
    i18n.store({ [LOCALE]: LANGUAGE_PACK })
    setHasSetTranslations(true)
  }, [])

  return hasSetTranslations ? children : null
}

function APITranslationsProvider({ children }: Props) {
  const [hasSetTranslations, setHasSetTranslations] = useState(false)
  const { data: translationPack } = useTranslationPack()

  useEffect(() => {
    if (translationPack) {
      i18n.store({ [LOCALE]: translationPack })
      setHasSetTranslations(true)
    }
  }, [translationPack])

  return hasSetTranslations ? children : null
}

export default function TranslationsProvider(props: Props) {
  const { loadTranslationPackFromAPIEnabled } = useFeatureFlags()

  return loadTranslationPackFromAPIEnabled ? (
    <APITranslationsProvider {...props} />
  ) : (
    <WindowConstantsTranslationsProvider {...props} />
  )
}
