/**
 * Using window.location for navigation is deprecated.
 *
 * It is used only for existing (legacy) cases where
 * single-page-application navigation can't be
 * used due to current design deficiencies, necessitating
 * a full app reload. The reason is not always the same,
 * but essentially stems from data fetching being
 * improperly abstracted from base rails views.
 */

export default (location?: string) => {
  if (location) {
    window.location.assign(location)
  } else {
    window.location.reload()
  }
}
