export type AvailableVouchersModalUIActions =
  | {
      type: 'availableVouchersModal/SHOW'
    }
  | {
      type: 'availableVouchersModal/HIDE'
    }

export const SHOW_AVAILABLE_VOUCHERS_MODAL = 'availableVouchersModal/SHOW'
export function showAvailableVouchersModal() {
  return {
    type: SHOW_AVAILABLE_VOUCHERS_MODAL,
  }
}

export const HIDE_AVAILABLE_VOUCHERS_MODAL = 'availableVouchersModal/HIDE'
export function hideAvailableVouchersModal() {
  return {
    type: HIDE_AVAILABLE_VOUCHERS_MODAL,
  }
}
