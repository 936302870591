import {
  serializeCartAddon,
  serializeCartItemCount,
  serializeChangeCartParticipant,
  serializeCartPromotion,
} from '~/serializers/cart'
import { creditCardSerializer } from '~/serializers/credit-card'
import orderSerializer from '~/serializers/order'
import { serializeProfile } from '~/serializers/profile'
import {
  serializeCreateReservation,
  serializeUpdateReservation,
} from '~/serializers/reservation'
import { serializeReservationCart } from '~/serializers/reservation-cart'
import { serializeVoucherShare } from '~/serializers/voucher-share'
import { authedFetch } from '~/utils/fetch'

import getAPICore from './get-api-core'
import getCustomerAPIEndpoints from './get-customer-api-endpoints'

import type {
  CustomerAPI,
  APICore,
  CustomerAPIEndpoints,
  NewCreditCard,
  ProductVariant,
  ProfileDetailsEdit,
  Reservation,
  VoucherShareRequest,
  ReturnAccessToken,
  AvailableUpsell,
} from '~/types'

export default function getCustomerAPI(): CustomerAPI {
  const api: APICore = getAPICore(authedFetch)
  const endpoints: CustomerAPIEndpoints = getCustomerAPIEndpoints()

  const createReservation = (data: Reservation): Promise<Response> => {
    return api.post(endpoints.reservationsPath, {
      body: JSON.stringify(serializeCreateReservation(data)),
    })
  }

  const updateReservation = (data: Reservation): Promise<Response> => {
    if (!data.id) return Promise.reject()

    return api.put(endpoints.reservationPath(data.id), {
      body: JSON.stringify(serializeUpdateReservation(data)),
    })
  }

  const createOrUpdateReservation = (data: Reservation): Promise<Response> => {
    if (data.id) return updateReservation(data)

    return createReservation(data)
  }

  return {
    core: api,
    getCartWaivers: (): Promise<Response> => {
      return api.get(endpoints.cartWaiversPath)
    },

    getWaivers(): Promise<Response> {
      return api.get(endpoints.waiversPath)
    },

    updateCartItemCount(
      productVariant: ProductVariant,
      count,
    ): Promise<Response> {
      return api.patch(endpoints.cartItemsPath, {
        body: JSON.stringify(serializeCartItemCount(productVariant, count)),
      })
    },

    addCartAddon(internalId: string, addonType: string): Promise<Response> {
      return api.post(endpoints.cartAddonsPath, {
        body: JSON.stringify(serializeCartAddon(internalId, addonType)),
      })
    },

    removeCartAddon(internalId: string, addonType: string): Promise<Response> {
      return api.delete(endpoints.cartAddonsPath, {
        body: JSON.stringify(serializeCartAddon(internalId, addonType)),
      })
    },

    changeCartParticipant(
      internalId: string,
      profileId: string | null | undefined,
    ): Promise<Response> {
      return api.patch(endpoints.cartItemsAssignPath(internalId), {
        body: JSON.stringify(serializeChangeCartParticipant(profileId)),
      })
    },

    addCartPromoCode(code: string): Promise<Response> {
      return api.post(endpoints.cartPromotionsPath, {
        body: JSON.stringify(serializeCartPromotion(code)),
      })
    },

    removeCartPromoCode(code: string): Promise<Response> {
      return api.delete(endpoints.cartPromotionsPath, {
        body: JSON.stringify(serializeCartPromotion(code)),
      })
    },

    removeGroupMember(profileId: string): Promise<Response> {
      return api.delete(endpoints.groupMembersPath(profileId))
    },

    createProfile(profile: Partial<ProfileDetailsEdit>): Promise<Response> {
      return api.post(endpoints.guestProfilesPath, {
        body: JSON.stringify(serializeProfile(profile)),
      })
    },

    updateProfile(profile: ProfileDetailsEdit): Promise<Response> {
      const id = profile.id || ''
      return api.put(endpoints.profilePath(id), {
        body: JSON.stringify({ data: { gender: profile.gender } }),
      })
    },

    setCartCreditCard(
      creditCard: NewCreditCard,
      saveCardOnFile: boolean,
    ): Promise<Response> {
      return api.post(endpoints.cartCreditCardPath, {
        body: JSON.stringify(
          creditCardSerializer({
            creditCard,
            saveCardOnFile,
          }),
        ),
      })
    },

    updateCartCreditCard({
      savedOnFileAt,
    }: {
      savedOnFileAt: string
    }): Promise<Response> {
      return api.put(endpoints.cartCreditCardPath, {
        body: JSON.stringify({
          data: {
            saved_on_file_at: savedOnFileAt,
          },
        }),
      })
    },

    createOrder(termsAcceptedAt: number): Promise<Response> {
      return api.post(endpoints.ordersPath, {
        body: JSON.stringify(
          orderSerializer({
            termsAcceptedAt,
          }),
        ),
      })
    },

    getRedemptions(): Promise<Response> {
      return api.get(endpoints.redemptionsPath)
    },

    getProfileMedia(
      profileId: string,
      activeMediaOnly?: boolean,
    ): Promise<Response> {
      return api.get(endpoints.profileMediaPath(profileId, activeMediaOnly))
    },

    upgradeCart(profileProductIds: string[]): Promise<Response> {
      return api.post(endpoints.cartUpgradePath, {
        body: JSON.stringify({
          data: profileProductIds,
        }),
      })
    },

    addUpgradesToCart(data: Record<number, string>): Promise<Response> {
      return api.post(endpoints.addUpgradesToCartPath, {
        body: JSON.stringify({
          data,
        }),
      })
    },

    getProfileProducts(): Promise<Response> {
      return api.get(endpoints.profileProductsPath)
    },

    deferProfileProduct({
      profileProductId,
      passDeferred,
    }: {
      profileProductId: number
      passDeferred: boolean
    }): Promise<Response> {
      return api.patch(endpoints.profileProductUpdatePath(profileProductId), {
        body: JSON.stringify({
          data: {
            pass_deferred: passDeferred,
          },
        }),
      })
    },

    updateGuest({
      currentPassword,
      password,
    }: {
      currentPassword: string
      password: string
    }): Promise<Response> {
      return api.patch(endpoints.guestPath, {
        body: JSON.stringify({
          data: {
            current_password: currentPassword,
            password: password,
          },
        }),
      })
    },

    cancelReservation(reservationId: number): Promise<Response> {
      return api.delete(endpoints.reservationPath(reservationId))
    },

    createReservation,
    updateReservation,
    createOrUpdateReservation,

    confirmReservationCart(data: {
      termsConditionsAcceptedAt: Date | null
    }): Promise<Response> {
      return api.post(endpoints.reservationCartConfirmPath, {
        body: JSON.stringify(serializeReservationCart(data)),
      })
    },

    getCredits(): Promise<Response> {
      return api.get(endpoints.creditsPath)
    },

    getReservationCart(): Promise<Response> {
      return api.get(endpoints.reservationCartPath)
    },

    deleteReservationCart(): Promise<Response> {
      return api.delete(endpoints.reservationCartPath)
    },

    getReservations(): Promise<Response> {
      return api.get(endpoints.reservationsPath)
    },

    getReservationAvailability(resortId: number | string): Promise<Response> {
      return api.get(endpoints.reservationAvailabilityPath(resortId))
    },

    checkoutWithAffirm(termsAcceptedAt: number): Promise<Response> {
      return api.post(endpoints.affirmCheckoutPath, {
        body: JSON.stringify({
          data: {
            terms_accepted_at: termsAcceptedAt,
          },
        }),
      })
    },

    getPromotionPrompts(internalId: string): Promise<Response> {
      return api.get(endpoints.cartItemPromotionPromptPath(internalId))
    },

    applyPromotionPrompt(
      internalId: string,
      {
        productId,
        variant,
      }: {
        productId: string
        variant: string
      },
    ): Promise<Response> {
      return api.post(endpoints.cartItemPromotionPromptPath(internalId), {
        body: JSON.stringify({
          data: {
            product_id: productId,
            variant,
          },
        }),
      })
    },

    acknowledgeCartChange(): Promise<Response> {
      return api.put(endpoints.acknowledgeCartChangePath)
    },

    getVoucherShareHistory(profileProductId: number): Promise<Response> {
      return api.get(endpoints.voucherShareHistoryPath(profileProductId))
    },

    shareVoucher(data: VoucherShareRequest): Promise<Response> {
      return api.post(endpoints.voucherSharePath, {
        body: JSON.stringify(serializeVoucherShare(data)),
      })
    },

    claimVouchers(tokens: string[]): Promise<Response> {
      return api.post(endpoints.claimVoucherSharePath, {
        body: JSON.stringify({
          data: {
            tokens,
          },
        }),
      })
    },

    returnVouchers(tokens: ReturnAccessToken[]): Promise<Response> {
      return api.post(endpoints.returnVoucherSharePath, {
        body: JSON.stringify({
          data: {
            tokens,
          },
        }),
      })
    },

    getNotifications(): Promise<Response> {
      return api.get(endpoints.notificationsPath)
    },

    dismissNotification(notificationId: number): Promise<Response> {
      return api.post(endpoints.notificationDismissPath(notificationId))
    },

    getRedeemableVouchers(): Promise<Response> {
      return api.get(endpoints.redeemableVouchersPath)
    },

    reportCheckoutInitiation(): Promise<Response> {
      return api.post(endpoints.facebookConversionsPath, {
        body: JSON.stringify({
          data: {
            event_type: 'initiate_checkout',
          },
        }),
      })
    },

    performUpsells(upsells: AvailableUpsell[]): Promise<Response> {
      return api.post(endpoints.cartTargetedUpsellPath, {
        body: JSON.stringify({
          data: upsells.map((upsell) => ({
            cart_item_internal_id: upsell.cartItem.internalId,
            product_code: upsell.product.id,
            variant: upsell.variant.ageCategory,
          })),
        }),
      })
    },

    initializeFreedomPayHpcSession(): Promise<Response> {
      return api.post(endpoints.freedomPayHpcSessionInitializePath)
    },

    createFreedomPayHpcCreditCard({
      sessionKey,
      paymentKey,
      savedOnFileAt,
      setCartCreditCard,
      nameOnCard,
    }: {
      sessionKey: string
      paymentKey: string
      savedOnFileAt: string | null | undefined
      setCartCreditCard: boolean
      nameOnCard: string
    }): Promise<Response> {
      return api.post(endpoints.freedomPayHpcCreateCreditCardPath, {
        body: JSON.stringify({
          data: {
            session_key: sessionKey,
            payment_key: paymentKey,
            saved_on_file_at: savedOnFileAt,
            set_cart_credit_card: setCartCreditCard,
            name_on_card: nameOnCard,
          },
        }),
      })
    },

    processSheerIdVerification(verificationId: string): Promise<Response> {
      return api.post(endpoints.sheerIdWebhookPath, {
        body: JSON.stringify({
          verificationId,
        }),
      })
    },

    getPromotionCodeAutoApplicationOptions(
      promotionCode: string,
    ): Promise<Response> {
      return api.get(endpoints.promotionCodeAutoApplicationPath(promotionCode))
    },

    applyPromotionCodeAutoApplicationOption({
      promotionCode,
      productCode,
      variantAgeCategory,
    }: {
      promotionCode: string
      productCode: string
      variantAgeCategory: string
    }): Promise<Response> {
      return api.post(endpoints.promotionCodeAutoApplicationPath(), {
        body: JSON.stringify({
          data: {
            promotion_code: promotionCode,
            product_code: productCode,
            variant_age_category: variantAgeCategory,
          },
        }),
      })
    },

    removePromotionCodeAutoApplicationOption({
      promotionCode,
      productCode,
      variantAgeCategory,
    }: {
      promotionCode: string
      productCode: string
      variantAgeCategory: string
    }): Promise<Response> {
      return api.delete(endpoints.promotionCodeAutoApplicationPath(), {
        body: JSON.stringify({
          data: {
            promotion_code: promotionCode,
            product_code: productCode,
            variant_age_category: variantAgeCategory,
          },
        }),
      })
    },

    getCreditCardOnFile(): Promise<Response> {
      return api.get(endpoints.creditCardOnFilePath)
    },

    useCreditCardOnFile(cvc: string): Promise<Response> {
      return api.post(endpoints.useCreditCardOnFilePath, {
        body: JSON.stringify({
          data: {
            cvc,
          },
        }),
      })
    },

    getBenefits(profileProductId: number): Promise<Response> {
      return api.get(endpoints.benefitsPath(profileProductId))
    },

    getPassUsage(profileProductId: number): Promise<Response> {
      return api.get(endpoints.passUsagePath(profileProductId))
    },

    getSharedVouchersSummary(profileProductId: number): Promise<Response> {
      return api.get(endpoints.sharedVouchersSummaryPath(profileProductId))
    },

    createMinorConsents(profileIds: string[]) {
      return api.post(endpoints.minorConsentsPath, {
        body: JSON.stringify({
          data: profileIds,
        }),
      })
    },

    removeCartItem(internalId: string) {
      return api.delete(endpoints.cartItemPath(internalId))
    },
  }
}

export { getCustomerAPI }
