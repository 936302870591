/**
 * Reducer for state related to the product cart.
 */
import { cloneDeep } from 'lodash'

import { CLEAR_CART, UPDATE_CART } from '~/actions/cart'

import type { CartActions } from '~/actions/cart'
import type { CartState } from '~/types'

const getInitialState = (): CartState => ({
  accessDisclaimerSignedAt: null,
  affirmCheckoutId: null,
  analytics: null,
  changed: null,
  createdAt: null,
  creditCard: null,
  deposit: null,
  errors: null,
  foundationDonation: 0,
  fraudCheckIdentifier: null,
  netTotal: 0,
  payloadCurrency: null,
  profileIdsRequiringMinorConsent: [],
  profiles: [],
  promotionCodes: [],
  purchaseGroup: null,
  savings: 0,
  subtotal: 0,
  taxTotal: 0,
  taxes: [],
  total: 0,
  totalCreditApplied: 0,
  updatedAt: 0,
  validForDonation: null,
  validForSummitSupport: null,
})

export default function (
  state = getInitialState(),
  action: CartActions,
): CartState {
  if (action.type === UPDATE_CART) {
    return cloneDeep(action.cart)
  }

  if (action.type === CLEAR_CART) {
    return getInitialState()
  }

  return state
}
