export type TermsAction =
  | {
      type: 'CHECK_TERMS'
    }
  | {
      type: 'ALERT_TERMS'
    }
  | {
      type: 'RESET_TERMS'
    }

export const CHECK_TERMS = 'CHECK_TERMS'
export const ALERT_TERMS = 'ALERT_TERMS'
export const RESET_TERMS = 'RESET_TERMS'
