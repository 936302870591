import delay from '~/utils/delay'

import getAPICore from './get-api-core'
import { authedFetch } from '../fetch'

export { signResortAuthorization } from './resort-authorization'
export { requestCatalog } from './product-catalog'
export { default as getCustomerAPI } from './get-customer-api'

export const ping = () => authedFetch('/api/v2/ping')

export function pingWithDelay(minDelay: number) {
  return Promise.all([ping(), delay(minDelay)])
}

export default getAPICore
