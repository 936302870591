export function humanize(str: string) {
  const split = str
    .trim()
    .replace(/[a-z][A-Z]/g, (match) => match[0] + ' ' + match[1])
  return split[0].toUpperCase() + split.substr(1)
}

export function parameterize(str: string) {
  return str
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '')
}

export function getPossessiveName(name: string, isGuest = false): string {
  if (isGuest) {
    return 'your'
  } else {
    return `${name}'s`.replace(/s's$/, "s'")
  }
}

export function stripSeasonFromProductTitle(season: string) {
  return season.replace(/^(\d{2}\/\d{2} ){1,}/, '')
}
