import classNames from 'classnames'
import React from 'react'

import './index.scss'

export type Props = {
  children: number
  solid?: boolean
  small?: boolean
  onDarkBackground?: boolean
  className?: string
}

export default function StepNumber({
  children,
  onDarkBackground = false,
  solid = false,
  small = false,
  className,
}: Props) {
  return (
    <span
      className={classNames('amp-step-number', className, {
        'on-dark-background': onDarkBackground,
        solid,
        small,
      })}
    >
      {children}
    </span>
  )
}
