import classNames from 'classnames'
import React from 'react'

import Button from '../button'
import StepNumber from '../step-number'
import './index.scss'

import type { ReactNode } from 'react'

const EditButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    type="icon"
    glyph="edit"
    aria-label="Edit"
    className="edit"
    onClick={onClick}
  />
)

type Props = {
  step: number
  currentStep: number
  title: ReactNode
  active?: ReactNode
  inactive?: ReactNode
  onEdit?: () => void
}

export default function Step({
  step,
  title,
  currentStep,
  active,
  inactive,
  onEdit,
}: Props) {
  const complete = currentStep > step

  return (
    <section
      className={classNames('amp-step', {
        active: currentStep === step,
        complete: complete,
      })}
    >
      <StepNumber solid={currentStep >= step}>{step}</StepNumber>
      <div className="step-header">
        <h2 className="step-title">{title}</h2>
        {complete && onEdit && <EditButton onClick={onEdit} />}
      </div>
      <div className="step-content">
        {currentStep === step && active}
        {currentStep !== step && inactive}
      </div>
    </section>
  )
}
