import {
  resetFeatureFlags as resetFeatureFlagsAction,
  setFeatureFlags as setFeatureFlagsAction,
} from '~/actions/feature-flags'

import type { FeatureFlags } from '~/types'

export const featureFlags: FeatureFlags = Object.freeze(
  (window.CONSTANTS && window.CONSTANTS.featureFlags) || {},
)

export const initFeatureFlagUtilities = (store: Record<string, any>) => {
  window.AMP.resetFeatureFlags = () => {
    store.dispatch(resetFeatureFlagsAction())
  }

  window.AMP.setFeatureFlags = (flags) => {
    store.dispatch(setFeatureFlagsAction(flags))
  }
}
