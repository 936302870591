export const NAVIGATION_PUSH = 'navigation/PUSH' as const
export const NAVIGATION_REPLACE = 'navigation/REPLACE' as const
export const NAVIGATION_BACK = 'navigation/BACK' as const

export type NavigationActions =
  | {
      type: 'navigation/PUSH'
      payload: {
        url: string
      }
    }
  | {
      type: 'navigation/REPLACE'
      payload: {
        url: string
      }
    }
  | {
      type: 'navigation/BACK'
    }

export const push = (url: string) => ({
  type: NAVIGATION_PUSH,
  payload: {
    url,
  },
})

export const replace = (url: string) => ({
  type: NAVIGATION_REPLACE,
  payload: {
    url,
  },
})

export const back = () => ({
  type: NAVIGATION_BACK,
})
