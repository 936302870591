import classNames from 'classnames'
import React from 'react'

import Button from '../button'

import type { Props as ButtonProps } from '../button'

import './responsive-button.scss'

const ResponsiveButton = (props: ButtonProps) => {
  const className = classNames(props.className, 'responsive')
  return <Button {...props} className={className} />
}

export default ResponsiveButton
