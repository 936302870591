import styled from 'styled-components'

import { ButtonV2 } from '~/components/button'
import ErrorMessage from '~/components/error-message'

export const FieldError = styled(ErrorMessage)`
  margin-top: 5px;
`

export const FieldWrapper = styled.div`
  margin: 0 0 23px 0;
`

export const SubmitButton = styled(ButtonV2)``
