import classNames from 'classnames'
import React from 'react'

import './text-input-sublabel.scss'

import type { ReactNode } from 'react'

export type Props = {
  children?: ReactNode
  className?: string
}

export default function TextInputSublabel({ children, className }: Props) {
  if (!children) return null

  return (
    <div className={classNames('amp-text-input-sublabel', className)}>
      {children}
    </div>
  )
}
