import {
  SET_PRODUCT_NAME_FILTER_VALUE,
  SET_PRODUCT_SEASON_ID_FILTER_VALUE,
} from '~/actions/ui/filters'

import type { AppActions } from '~/actions/types'

export const ALL_SEASONS_ID = 0

export type State = {
  productName: string
  productSeasonId: number
}

const INITIAL_STATE = {
  productName: '',
  productSeasonId: ALL_SEASONS_ID,
}

export default function filters(
  state: State = INITIAL_STATE,
  action: AppActions,
): State {
  switch (action.type) {
    case SET_PRODUCT_NAME_FILTER_VALUE:
      return { ...state, productName: action.value }
    case SET_PRODUCT_SEASON_ID_FILTER_VALUE:
      return { ...state, productSeasonId: action.value }
    default:
      return state
  }
}
