import { camelizeKeys } from 'humps'

import { handleRequestFailure } from '~/actions/request'
import getCustomerAPI from '~/utils/api/get-customer-api'
import HTTPError from '~/utils/http-error'

import { reportGroupedError } from '../utils/logger'

import type { CustomerAPI, AdminAPI, ProfileProduct } from '~/types'

type Dispatch = (...args: Array<any>) => any

export const LOAD_PROFILE_PRODUCTS_START = 'profileProducts/LOAD_START'
export const LOAD_PROFILE_PRODUCTS_SUCCESS = 'profileProducts/LOAD_SUCCESS'
export const LOAD_PROFILE_PRODUCTS_FAIL = 'profileProducts/LOAD_FAIL'
export const DEFER_PROFILE_PRODUCT = 'deferProfileProduct'
export type ProfileProductsActions =
  | {
      type: 'profileProducts/LOAD_START'
    }
  | {
      type: 'profileProducts/LOAD_SUCCESS'
      data: ProfileProduct[]
    }
  | {
      type: 'profileProducts/LOAD_FAIL'
    }
  | {
      type: 'deferProfileProduct'
      profileProductId: number
    }

function handleFailure(dispatch: (...args: Array<any>) => any) {
  dispatch(handleRequestFailure())
  return dispatch({
    type: LOAD_PROFILE_PRODUCTS_FAIL,
  })
}

export function loadProfileProducts({
  api,
  reload,
}: {
  api?: CustomerAPI | AdminAPI
  reload?: boolean
} = {}) {
  // Reloading is opt-in by default
  if (reload === undefined) reload = false

  return async (dispatch: Dispatch, getState: (...args: Array<any>) => any) => {
    const { profileProducts } = getState()

    if (!reload && (profileProducts.loaded || profileProducts.loading)) return

    dispatch({
      type: LOAD_PROFILE_PRODUCTS_START,
    })

    try {
      if (api === undefined) api = getCustomerAPI()

      const response = await api.getProfileProducts()

      if (response.ok) {
        const { data } = await response.json()
        return dispatch({
          type: LOAD_PROFILE_PRODUCTS_SUCCESS,
          data: camelizeKeys(data),
        })
      } else {
        throw new HTTPError('failed: GET', response, await response.text())
      }
    } catch (err) {
      reportGroupedError('loadProfileProducts', err)
      return handleFailure(dispatch)
    }
  }
}
