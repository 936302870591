import { START_SAVING_CC, STOP_SAVING_CC } from '../../actions/credit-card'

import type { AppActions } from '../../actions/types'

export default (state = false, action: AppActions): boolean => {
  switch (action.type) {
    case START_SAVING_CC:
      return true

    case STOP_SAVING_CC:
      return false

    default:
      return state
  }
}
