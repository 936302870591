import Cookies from 'js-cookie'

import { SESSION_COOKIE_NAME } from '../config'

// This is required so the cookie does expire before we redirect/logout.
const OFFSET = 2000

export type RawCookie = {
  warning_time: string
  expiry_time: string
  expires_at: string
  authenticated: boolean
}

type CookieData = {
  warningTime: number
  expiryTime: number
  authenticated: boolean
}

// Paths which have custom session expiration handling are exempt from
// needing to show the session expiration warning modal.
const SESSION_WARNING_EXEMPTIONS = [new RegExp('/myaccount/reservations/add.*')]

export const shouldWarnSessionExpiry = ({
  cartHasItems,
  hasOnboardingPii,
  pathname,
  isAuthenticated,
}: {
  cartHasItems: boolean
  hasOnboardingPii?: boolean
  pathname: string
  isAuthenticated: boolean
}) => {
  // Do not warn of session expiry if the path is specified as exempt.
  if (SESSION_WARNING_EXEMPTIONS.some((regex) => regex.test(pathname)))
    return false

  return shouldRedirectOnSessionExpiry({
    isAuthenticated,
    cartHasItems,
    hasOnboardingPii,
  })
}

export const shouldRedirectOnSessionExpiry = ({
  cartHasItems,
  hasOnboardingPii,
  isAuthenticated,
}: {
  cartHasItems: boolean
  hasOnboardingPii?: boolean
  isAuthenticated: boolean
}) => isAuthenticated || cartHasItems || Boolean(hasOnboardingPii)

export const redirectUri = (isAuthenticated: boolean) => {
  const path = isAuthenticated ? '/login?expired=true' : '/cart?expired=true'
  return `${window.location.origin}${path}`
}

export class SessionCookie {
  static defaults() {
    return {
      warningTime: 10,
      expiryTime: 15,
      expiresAt: 0,
      // By default session should be expired.
      authenticated: false,
    }
  }

  static rawCookie() {
    return JSON.parse(Cookies.get(SESSION_COOKIE_NAME) || '{}') as RawCookie
  }

  static cookie(): CookieData {
    const defaults = this.defaults()
    const rawCookie = this.rawCookie()
    return {
      warningTime: Number(rawCookie.warning_time || defaults.warningTime),
      expiryTime: Number(rawCookie.expiry_time || defaults.expiryTime),
      authenticated: rawCookie.authenticated || defaults.authenticated,
    }
  }

  static expiresIn(): number {
    return this.getExpiresAt() - OFFSET - Date.now()
  }

  static isAuthenticated(): boolean {
    return !!this.cookie()['authenticated']
  }

  static getExpiryTime(): number {
    return this.cookie()['expiryTime'] * 60 * 1000
  }

  static getWarningTime(): number {
    return this.cookie()['warningTime'] * 60 * 1000
  }

  static getExpiresAt(): number {
    const defaults = this.defaults()
    const rawCookie = this.rawCookie()

    if (rawCookie.expires_at) {
      return Date.parse(rawCookie.expires_at)
    } else {
      return defaults.expiresAt
    }
  }
  static isSessionExpired() {
    return this.expiresIn() < 0
  }

  static isSessionExpirationImminent() {
    return this.expiresIn() < this.getWarningTime()
  }
}

export default SessionCookie
