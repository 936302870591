import type { RedemptionsAction } from '~/actions/redemptions'
import type { ProfileRedemptionsData } from '~/types'

export type RedemptionsState = {
  readonly loading: boolean
  readonly loaded: boolean
  readonly data: ProfileRedemptionsData[]
}

const defaultState: RedemptionsState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function (
  state: RedemptionsState = defaultState,
  action: RedemptionsAction,
): RedemptionsState {
  switch (action.type) {
    case 'redemptions/LOADING':
      return { ...state, loading: true }

    case 'redemptions/LOADED':
      return { ...state, loading: false, loaded: true, data: action.data }

    case 'redemptions/LOAD_FAILED':
      return { ...state, loading: false }

    default:
      return state
  }
}
