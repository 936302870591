import type { RenewalProduct, API$RenewalProduct } from '~/types'

export function renewalProductDeserializer(
  data: API$RenewalProduct,
): RenewalProduct {
  const renewalProduct: RenewalProduct = {
    ageCategory: data.age_category,
    displayAgeRange: data.display_age_range,
    productName: data.title,
    availableCurrencies: data.available_currencies,
  }

  if (data.restriction_warning) {
    renewalProduct.restrictionWarning = data.restriction_warning
  }

  return renewalProduct
}
