import { createGlobalStyle } from 'styled-components'

import base from './base'
import containers from './containers'
import reset from './reset'

import './icons/style.scss'

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${base}

  svg {
    fill: currentColor;
  }

  ${containers}
`

export default GlobalStyle
