import {
  SHOW_PENDING_PROFILE_SHARES_MODAL,
  HIDE_PENDING_PROFILE_SHARES_MODAL,
} from '~/actions/ui/pending-profile-shares-modal'

import type { AppActions } from '~/actions/types'

const initialState = {
  visible: false,
}

export type State = {
  visible: boolean
}

export default (state: State = initialState, action: AppActions): State => {
  switch (action.type) {
    case SHOW_PENDING_PROFILE_SHARES_MODAL:
      return {
        visible: true,
      }

    case HIDE_PENDING_PROFILE_SHARES_MODAL:
      return {
        visible: false,
      }

    default:
      return state
  }
}
