let storeInstance = null

export const setStoreInstance = (store: any) => {
  if (storeInstance) {
    console.error('The store instance is already set.')
  } else {
    storeInstance = store
  }
}

export const getStoreInstance = (): any => {
  if (!storeInstance) {
    console.error('storeInstance is not defined')
  }

  return storeInstance
}
