import classNames from 'classnames'
import React, { PureComponent } from 'react'

import { scrollToElement } from '../../utils/scrolling'
import './error-message.scss'
import ContentBlock from '../content-block'
import Icon from '../icon'

import type { ReactNode } from 'react'

export type Props = {
  children: ReactNode
  scrollIntoView?: boolean
  type?: 'block' | null | undefined
  className?: string
}

export default class ErrorMessage extends PureComponent<Props> {
  static defaultProps = {
    hideIcon: false,
    scrollIntoView: false,
  }

  errorDiv: HTMLDivElement | null | undefined

  componentDidMount() {
    if (this.props.scrollIntoView && this.errorDiv) {
      scrollToElement(this.errorDiv, {
        topBuffer: 200,
      })
    }
  }

  renderIcon() {
    const { type } = this.props

    if (type === 'block') {
      return (
        <div className="icon">
          <Icon glyph="alert" />
        </div>
      )
    }
  }

  renderError() {
    const error = this.props.children

    return React.Children.map(error, (item, index) => {
      if (typeof item === 'string') {
        return (
          <ContentBlock
            buttonType="inline"
            key={index}
            className="keep-to-preserve-div"
          >
            {item}
          </ContentBlock>
        )
      }

      return <span key={index}>{item}</span>
    })
  }

  render() {
    return (
      <div
        className={classNames(
          'amp-error-message',
          this.props.type,
          this.props.className,
        )}
        ref={(el) => {
          this.errorDiv = el
        }}
        data-testid="error-message"
      >
        {this.renderIcon()}
        <div className="content">{this.renderError()}</div>
      </div>
    )
  }
}
