import {
  START_ADD_UPGRADES,
  STOP_ADD_UPGRADES,
  SHOW_UPGRADES_ADDED,
  HIDE_UPGRADES_ADDED,
} from '~/actions/ui/upgrades'

import type { AppActions } from '~/actions/types'

export type State = {
  addingUpgrades: boolean
  showUpgradesAdded: boolean
}

export default function upgrades(
  state: State = {
    addingUpgrades: false,
    showUpgradesAdded: false,
  },
  action: AppActions,
): State {
  switch (action.type) {
    case START_ADD_UPGRADES:
      return { ...state, addingUpgrades: true }

    case STOP_ADD_UPGRADES:
      return { ...state, addingUpgrades: false }

    case SHOW_UPGRADES_ADDED:
      return { ...state, showUpgradesAdded: true }

    case HIDE_UPGRADES_ADDED:
      return { ...state, showUpgradesAdded: false }

    default:
      return state
  }
}
