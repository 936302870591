import { camelizeKeys } from 'humps'

import type { Reservation, API$Reservation } from '~/types'

export default function reservationsDeserializer(
  reservations: API$Reservation[],
): Reservation[] {
  return reservations
    .map((reservation) => camelizeKeys(reservation))
    .sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
}
