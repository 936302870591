import type { ErrorCode, PromoErrorCode } from './validations.types'

export const BASE_ERROR_KEY = '_base'

export const AGE_INVALID: ErrorCode = 'AGE_INVALID'
export const ALREADY_REGISTERED: ErrorCode = 'ALREADY_REGISTERED'
export const DISABLED: PromoErrorCode = 'DISABLED'
export const EXPIRED: PromoErrorCode = 'EXPIRED'
export const HAS_UNCLAIMED_ONBOARDING: ErrorCode = 'HAS_UNCLAIMED_ONBOARDING'
export const IN_DISTANT_PAST: ErrorCode = 'IN_DISTANT_PAST'
export const IN_FUTURE: ErrorCode = 'IN_FUTURE'
export const INVALID_CHARACTERS: ErrorCode = 'INVALID_CHARACTERS'
export const INVALID: ErrorCode = 'INVALID'
export const NOT_APPLIED: PromoErrorCode = 'NOT_APPLIED'
export const NOT_FOUND: PromoErrorCode = 'NOT_FOUND'
export const NOT_UNIQUE: ErrorCode = 'NOT_UNIQUE'
export const OUT_OF_RANGE: ErrorCode = 'OUT_OF_RANGE'
export const RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED'
export const REDEEMED: PromoErrorCode = 'REDEEMED'
export const REQUIRED: ErrorCode = 'REQUIRED'
export const SYSTEM: PromoErrorCode = 'SYSTEM'
export const UNKNOWN_ERROR: ErrorCode = 'UNKNOWN_ERROR'
export const WRONG_CURRENCY: ErrorCode = 'WRONG_CURRENCY'
export const MIN_CHILD = 5
export const MIN_TEEN = 13
export const MIN_ADULT = 18
export const MIN_SENIOR = 70
