import getLoginState from './enhanced-commerce-tracking/get-login-state'

import type { CartState, GuestProfile } from '~/types'

export function serializePurchase(
  cart: CartState,
  id: number,
  affirmPayment: boolean,
) {
  const {
    revenue,
    coupon,
    affiliation,
    products,
    depositAmount,
    paymentPlanSelected,
  } = cart.analytics
  return {
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id,
          revenue,
          coupon,
          affiliation,
          deposit: paymentPlanSelected,
          depositAmount,
          affirm: affirmPayment,
        },
        products,
      },
    },
  }
}

export function authenticationSerializer(profile: GuestProfile) {
  return {
    userId: profile.id,
    event: 'authentication',
    ...getLoginState(profile),
  }
}

function removedProducts(
  cartBeforeRemoval: CartState,
  cartAfterRemoval: CartState,
) {
  return cartBeforeRemoval.analytics.products.reduce((acc, item) => {
    const { id } = item
    const stillPresentItem = cartAfterRemoval.analytics.products.find(
      ({ id: rId }) => rId === id,
    )

    if (!stillPresentItem) {
      acc.push(item)
      return acc
    }

    if (stillPresentItem.quantity === item.quantity) {
      return acc
    }

    // Item is still present but the quantity has changed.
    acc.push({ ...item, quantity: item.quantity - stillPresentItem.quantity })
    return acc
  }, [])
}

function addedProducts(cartAfterAdd: CartState) {
  return cartAfterAdd.analytics.products
}

export function serializeRemove(
  cartBeforeRemoval: CartState,
  cartAfterRemoval: CartState,
) {
  return {
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: removedProducts(cartBeforeRemoval, cartAfterRemoval),
      },
    },
  }
}

export function serializeAdd(cartAfterAdd: CartState) {
  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: cartAfterAdd.analytics.currency,
      add: {
        products: addedProducts(cartAfterAdd),
      },
    },
  }
}

export function serializeCheckoutStep(
  cart: CartState,
  stepNumber: number,
  option?: string,
) {
  return {
    event: 'checkout',
    ecommerce: {
      currencyCode: cart.analytics.currency,
      checkout: {
        actionField: {
          step: stepNumber,
          option: option || '',
        },
        products: cart.analytics.products,
      },
    },
  }
}

export function serializeCart(cart: CartState) {
  return {
    ecommerce: {
      checkout: {
        actionField: undefined,
        products: cart.analytics.products,
      },
    },
  }
}

export function serializePageView(
  virtualUrl?: string,
  referrerOverride?: string,
) {
  let pageView = {
    event: 'pageview',
    virtualUrl,
  }
  // @ts-expect-error TS2322
  if (referrerOverride) pageView = { ...pageView, referrerOverride }
  return pageView
}
