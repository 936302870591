/**
 * Defines actions and action creators related to groups.
 */

import HTTPError from '~/utils/http-error'

import { authChecked } from './auth'
import { groupDeserializer } from '../deserializers/group'
import { authedFetch } from '../utils/fetch'
import { reportGroupedError } from '../utils/logger'

import type { GroupMember } from '~/types'

//
// - Actions and Sync Action Creators
//
export type GroupLoadedAction = {
  type: 'GROUP_LOADED'
  loaded: boolean
  data: GroupMember[]
}
export type GroupLoadingAction = {
  type: 'GROUP_LOADING'
}

export const GROUP_LOADED = 'GROUP_LOADED'
export const GROUP_LOADING = 'GROUP_LOADING'
export function groupLoaded(groupMembers: GroupMember[]): GroupLoadedAction {
  return {
    type: GROUP_LOADED,
    loaded: true,
    data: groupMembers,
  }
}
export function groupLoading() {
  return {
    type: GROUP_LOADING,
  }
}

//
// - Async Action Creators
//
export function loadGroup({
  reload,
}: {
  reload?: boolean
} = {}) {
  if (reload === undefined) reload = false

  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    try {
      const {
        groupMembers: { loaded, loading },
      } = getState()
      if (!reload && (loaded || loading)) return

      dispatch(groupLoading())

      const resp = await authedFetch('/api/v2/me/group', {}, null)

      if (resp.ok) {
        const { data } = await resp.json()
        dispatch(groupLoaded(groupDeserializer(data)))
      } else if (resp.status === 401) {
        dispatch(authChecked(false))
      } else {
        throw new HTTPError('Unable to fetch group', resp, '')
      }
    } catch (error) {
      reportGroupedError('loadGroup', error)
    }
  }
}
