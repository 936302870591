/**
 * Defines actions and action creators related to the state being updated.
 */
//
// - Actions and Sync Action Creators
//
import { pingWithDelay } from '~/utils/api'
import { reportGroupedError } from '~/utils/logger'

export const UPDATE_SESSION_REQUEST = 'UPDATE_SESSION_REQUEST'
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS'
export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE'

export type Actions =
  | {
      type: 'UPDATE_SESSION_REQUEST'
    }
  | {
      type: 'UPDATE_SESSION_SUCCESS'
      lastUpdated: number
    }
  | {
      type: 'UPDATE_SESSION_FAILURE'
    }

const MIN_DELAY = 30000 // 30 seconds

type State = {
  session: {
    lastUpdated: number
  }
}

function sessionDidUpdateRecently({
  session: { lastUpdated },
}: State): boolean {
  if (lastUpdated == null) return false

  const timeSinceLastUpdate = Date.now() - lastUpdated
  return timeSinceLastUpdate < MIN_DELAY
}

export function extendSessionInBackground() {
  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    if (sessionDidUpdateRecently(getState())) return

    try {
      await pingWithDelay(500)
      dispatch({
        type: UPDATE_SESSION_SUCCESS,
        lastUpdated: Date.now(),
      })
    } catch (error) {
      reportGroupedError('extendSessionInBackground', error)
    }
  }
}

export function extendSession() {
  return async (dispatch: (...args: Array<any>) => any) => {
    dispatch({
      type: UPDATE_SESSION_REQUEST,
    })

    try {
      await pingWithDelay(500)
      dispatch({
        type: UPDATE_SESSION_SUCCESS,
        lastUpdated: Date.now(),
      })
    } catch (error) {
      dispatch({
        type: UPDATE_SESSION_FAILURE,
      })
      reportGroupedError('extendSession', error)
    }
  }
}
