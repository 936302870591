import { combineReducers } from 'redux'

import { SIGNING_UP } from '../../actions/sign-up'

export type State = {
  signingUp: boolean
}

function signingUp(state = false, action) {
  switch (action.type) {
    case SIGNING_UP:
      return action.state

    default:
      return state
  }
}

export default combineReducers<any>({
  signingUp,
})
