import { combineReducers } from 'redux'

import {
  START_SET_CART_CC,
  SET_CART_CC_FAILED,
  SET_CART_CC_SUCCESS,
} from '../../actions/ui/payment'

import type { AppActions } from '../../actions/types'

function savingCreditCard(state = false, action: AppActions): boolean {
  switch (action.type) {
    case START_SET_CART_CC:
      return true

    case SET_CART_CC_FAILED:
    case SET_CART_CC_SUCCESS:
      return false

    default:
      return state
  }
}

function saveCreditCardError(
  state: string | null | undefined = null,
  action: AppActions,
): string | null | undefined {
  switch (action.type) {
    case START_SET_CART_CC:
    case SET_CART_CC_SUCCESS:
      return null

    case SET_CART_CC_FAILED:
      return action.errorMessage

    default:
      return state
  }
}

export default combineReducers<any>({
  savingCreditCard,
  saveCreditCardError,
})
