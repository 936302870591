import classNames from 'classnames'
import React from 'react'

import { TextInputLabel } from './styles'

import type { ReactNode } from 'react'

export type Props = {
  children?: ReactNode
  className?: string
  disabled?: boolean
  hasError?: boolean
}

export default (props: Props) => {
  const { children, className, disabled, hasError } = props
  if (!children) return null

  return (
    <TextInputLabel
      className={classNames('amp-text-input-label', className, {
        disabled,
        'has-error': hasError,
      })}
    >
      {children}
    </TextInputLabel>
  )
}
