import type { APICore } from '~/types'

type RequestOptions = Record<string, any>

function getHeaders(
  headers: Record<string, any> | null | undefined = {},
  contentType?: string,
): Record<string, any> {
  return {
    ...headers,
    ...(contentType
      ? {
          'Content-Type': 'application/json',
        }
      : {}),
  }
}

function getOptions(
  options: RequestOptions,
  method: string,
  contentType?: string,
): RequestOptions {
  return {
    ...options,
    method: method,
    headers: getHeaders(options.headers, contentType),
  }
}

export default function getAPICore(
  fetch: (url: string, options: RequestOptions) => Promise<Response>,
): APICore {
  return {
    fetch: fetch,

    get(url: string, options: RequestOptions = {}): Promise<Response> {
      return this.fetch(url, getOptions(options, 'GET'))
    },

    post(url: string, options: RequestOptions = {}): Promise<Response> {
      return this.fetch(url, getOptions(options, 'POST', 'application/json'))
    },

    put(url: string, options: RequestOptions = {}): Promise<Response> {
      return this.fetch(url, getOptions(options, 'PUT', 'application/json'))
    },

    patch(url: string, options: RequestOptions = {}): Promise<Response> {
      return this.fetch(url, getOptions(options, 'PATCH', 'application/json'))
    },

    delete(url: string, options: RequestOptions = {}): Promise<Response> {
      return this.fetch(url, getOptions(options, 'DELETE', 'application/json'))
    },
  }
}
