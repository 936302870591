import classNames from 'classnames'
import React from 'react'

import Button from '../button'

import type { Props as ButtonProps } from '../button'
import type { ReactNode } from 'react'

import './modal-actions.scss'

type ModalButton = ButtonProps & {
  text: string
}
type ModalAction = ModalButton | (() => ReactNode)

export type Props = {
  primary?: ModalAction
  secondary?: ModalAction
}

function PrimaryButton(props: ModalButton) {
  return (
    <Button
      type={['primary']}
      {...props}
      className={classNames('modal-primary-action', props.className)}
    >
      {props.text}
    </Button>
  )
}

function SecondaryButton(props: ModalButton) {
  return (
    <Button
      type={['plain']}
      {...props}
      className={classNames('modal-secondary-action', props.className)}
    >
      {props.text}
    </Button>
  )
}

function PrimaryAction({ action }: { action: ModalAction }) {
  if (typeof action === 'function') {
    return <div className="modal-primary-action">{action()}</div>
  } else {
    return <PrimaryButton data-testid="modal-primary-action" {...action} />
  }
}

function SecondaryAction({ action }: { action: ModalAction }) {
  if (typeof action === 'function') {
    return <div className="modal-secondary-action">{action()}</div>
  } else {
    return <SecondaryButton data-testid="modal-secondary-action" {...action} />
  }
}

export default function ModalActions(props: Props) {
  return (
    <div className="actions">
      {props.secondary && <SecondaryAction action={props.secondary} />}
      {props.primary && <PrimaryAction action={props.primary} />}
    </div>
  )
}
