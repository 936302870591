import type { Currency, ProductVariant } from '~/types'

type CartItemCountRequestBody = {
  data: {
    variant: string
    type: string
    count: number
  }
}

export function serializeCartItemCount(
  productVariant: ProductVariant,
  count: number,
): CartItemCountRequestBody {
  return {
    data: {
      variant: productVariant.ageCategory,
      type: productVariant.productId,
      count: count,
    },
  }
}

type CartAddonRequestBody = {
  data: {
    id: string
    addon: string
  }
}

export function serializeCartAddon(
  internalId: string,
  addonType: string,
): CartAddonRequestBody {
  return {
    data: {
      id: internalId,
      addon: addonType,
    },
  }
}

export function serializeChangeCartParticipant(
  profileId: string | null | undefined,
): {
  data: {
    profile_id: string | null | undefined
  }
} {
  return {
    data: {
      profile_id: profileId,
    },
  }
}

type CartPromotionRequestBody = {
  code: string
  type: string
}

export function serializeCartPromotion(code: string): CartPromotionRequestBody {
  return {
    code: code,
    type: 'promotion',
  }
}

export const serializeUpdateCart = ({ currency }: { currency: Currency }) => ({
  data: {
    payload_currency: currency,
  },
})
