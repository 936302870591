import { combineReducers } from 'redux'

import availableVouchersModal from './available-vouchers-modal'
import cart from './cart'
import createMyAccount from './create-my-account'
import creditCardSaving from './credit-card-saving'
import filters from './filters'
import insuranceCheckModal from './insurance-check-modal'
import payment from './payment'
import pendingProfileSharesModal from './pending-profile-shares-modal'
import profile from './profile'
import upgrades from './upgrades'

import type { State as AvailableVouchersModalState } from './available-vouchers-modal'
import type { State as CreateMyAccountUI } from './create-my-account'
import type { State as InsuranceCheckModalState } from './insurance-check-modal'
import type { State as PendingProfileSharesModalState } from './pending-profile-shares-modal'
import type { State as UpgradesUIState } from './upgrades'
import type {
  CartUI,
  PaymentUI,
  ProfileUI,
  ResortFavoriteSettingsUI,
} from '~/types'

export type UIState = {
  availableVouchersModal: AvailableVouchersModalState
  cart: CartUI
  createMyAccount: CreateMyAccountUI
  creditCardSaving: boolean
  insuranceCheckModal: InsuranceCheckModalState
  filters: {
    productName: string
    productSeasonId: number
  }
  payment: PaymentUI
  pendingProfileSharesModal: PendingProfileSharesModalState
  profile: ProfileUI
  resortFavoriteSettings: ResortFavoriteSettingsUI
  upgrades: UpgradesUIState
}

export default combineReducers<any>({
  availableVouchersModal,
  cart,
  createMyAccount,
  creditCardSaving,
  insuranceCheckModal,
  filters,
  pendingProfileSharesModal,
  profile,
  payment,
  upgrades,
})
