import HTTPError from '~/utils/http-error'

type Extra = Record<string, any>

export const getErrorCode = (error: Error | HTTPError): string => {
  // @ts-expect-error TS2339
  return error.context && typeof error.context.text === 'string'
    ? // @ts-expect-error TS2339
      error.context.text
    : ''
}

export type ContextError = Error & {
  context?: Extra
}

export function reportGroupedError(
  name: string,
  error: ContextError,
  additionalDetails?: Record<string, any>,
) {
  console.error(error, {
    name: name,
    context: {
      ...(additionalDetails || {}),
      ...(error.context || {}),
    },
    fingerprint: `${name} : ${error.name}`, // ensures correct grouping
  })
}
