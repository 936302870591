import styled, { css } from 'styled-components'

import { color } from '~/styles/utils'

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  column-gap: 8px;
`

export const Input = styled.input<{
  $hasError: boolean
}>`
  flex-shrink: 0;
  appearance: none;
  background-color: ${({ theme }) => theme.color.background.input.secondary};
  border: 1px solid #757575;
  float: left;
  width: 24px;
  height: 24px;
  line-height: 20px;
  && {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &:checked,
  &:indeterminate {
    border-color: ${color('blueCreek')};
    background-color: ${color('blueCreek')};
    color: ${color('white')};
    position: relative;

    &:disabled {
      background-color: #ededed;
    }

    &::before {
      font-family: 'icomoon' !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:checked::before {
    content: '\\e918';
    font-size: 13px;
    padding-left: 1px;
  }

  &:indeterminate::before {
    font-family: 'icomoon' !important;
    content: '\\f0ca';
    font-size: 12px;
  }

  &:disabled {
    border-color: #ededed;
    cursor: default;
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: #ea1609;
    `}
`

export const Label = styled.div<{ $disabled: boolean; $hasError: boolean }>`
  font-size: 16px;

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: #d91208;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${color('textDisabled')};
    `}
`
