import { AUTHENTICATED } from '~/actions/auth'
import { NAVIGATION_LOCATION_CHANGE_ACTION_TYPE } from '~/hooks/use-location-change-handler'

import getLoginState from './get-login-state'
import adobeTracker from '../adobe-visitor-api-serializer'
import getDomain from '../domain'
import {
  serializePurchase,
  serializeRemove,
  serializeAdd,
  serializeCheckoutStep,
  serializeCart,
  serializePageView,
} from '../gtm-data-layer-serializers'
import { getLocale, pathnameIncludesLocale, pathWithoutLocale } from '../locale'

import type { LoginState, TrackedEventInfo } from './types'
import type { CartState } from '~/types'

window.dataLayer = window.dataLayer || []
export const TRACK_PAGE_VIEW = 'TRACK_PAGE_VIEW'

const NO_TRACK = [
  '/redirect',
  '/register/add-tickets',
  '/renew',
  '/loading-profile',
]

const FACEBOOK_URL = 'https://www.facebook.com/'

const PAGES_WITH_STEP_TRACKING = {
  '/cart': {
    stepNumber: 1,
    option: 'cart',
  },
  '/create-my-account': {
    stepNumber: 2,
    option: 'createMyAccount',
  },
  '/assign-passes': {
    stepNumber: 3,
    option: 'assignPasses',
  },
  '/waiver': {
    stepNumber: 4,
    option: 'waiver',
  },
  '/payment': {
    stepNumber: 5,
    option: 'payment',
  },
  '/payment/creditcard': {
    stepNumber: 6,
    option: 'creditCard',
  },
  '/payment/review': {
    stepNumber: 7,
    option: 'review',
  },
}

type StepInfo =
  | {
      stepNumber: number
      option: string
    }
  | null
  | undefined

function getStepInfo(pathname: string): StepInfo {
  return PAGES_WITH_STEP_TRACKING[pathWithoutLocale(pathname)]
}

function isNoTrack(pathname: string) {
  return NO_TRACK.indexOf(pathname) >= 0
}

const getPathnameWithLocale = (pathname: string | null | undefined) =>
  pathname ? `/${getLocale()}${pathname}` : ''

function onLocationChange(
  cart: Record<string, any>,
  pathname: string,
  virtualUrl: string | null | undefined,
  loginState: LoginState,
) {
  // Do not do any thing if prefixed with a locale.
  // These navgation events will only be on initial app load,
  // which will be followed by a redirect to a url stripped of locale.
  // However, this should never happen, as the mechanism which triggers these
  // events ignores urls prepended with a locale.
  if (pathnameIncludesLocale(pathname) || isNoTrack(pathname)) return

  let referrerOverride

  if (document.referrer.startsWith(FACEBOOK_URL)) {
    referrerOverride = getDomain()
  }

  pageView(getPathnameWithLocale(virtualUrl) || undefined, referrerOverride)
  window.dataLayer.push(loginState)

  const stepInfo = getStepInfo(getPathnameWithLocale(pathname))
  // @ts-expect-error TS2345
  if (stepInfo) checkoutStep(cart, stepInfo.stepNumber, stepInfo.option)
}

export function enhancedCommerceMiddleware({ getState }: Record<string, any>) {
  return (next: (action: Record<string, any>) => Record<string, any>) =>
    (action: { type: string; payload: Record<string, any> }) => {
      const { cart, profile } = getState()

      if (
        action.type === NAVIGATION_LOCATION_CHANGE_ACTION_TYPE ||
        action.type === TRACK_PAGE_VIEW
      ) {
        const {
          payload: {
            location: { pathname },
            virtualUrl,
          },
        } = action
        onLocationChange(cart, pathname, virtualUrl, getLoginState(profile))
      }

      if (action.type === AUTHENTICATED) {
        window.dataLayer.push(getLoginState(profile))
      }

      return next(action)
    }
}

export function trackPageView(pathname: string) {
  return {
    type: TRACK_PAGE_VIEW,
    payload: {
      location: {
        pathname,
      },
      virtualUrl: pathname,
    },
  }
}

function purchase(cart: CartState, id: number, affirmPayment: boolean) {
  window.dataLayer.push(serializePurchase(cart, id, affirmPayment))

  if (window.adobe != undefined && window.adobe.target != undefined) {
    window.adobe.target.trackEvent(adobeTracker.serializePurchase(cart, id))
  }
}

function remove(cartBefore: CartState, cartAfter: CartState) {
  window.dataLayer.push(serializeRemove(cartBefore, cartAfter))
}

function add(cartAfter: CartState) {
  window.dataLayer.push(serializeAdd(cartAfter))
}

function checkoutStep(cart: CartState, stepNumber: number, option?: string) {
  window.dataLayer.push(serializeCheckoutStep(cart, stepNumber, option))
}

function cart(cart: CartState) {
  window.dataLayer.push(serializeCart(cart))
}

function pageView(url?: string, override?: string) {
  window.dataLayer.push(serializePageView(url, override))
}

function link(eventInfo: TrackedEventInfo) {
  window.dataLayer.push({
    event: 'customLink',
    eventInfo: {
      type: 'other',
      ...eventInfo,
    },
  })
}

export default {
  purchase,
  remove,
  add,
  checkoutStep,
  cart,
  link,
}
