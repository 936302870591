import * as actions from '../actions/photo-upload'

import type { Action } from '../actions/photo-upload'
import type { PhotoUploadState } from '~/types'

const DEFAULT_STATE: PhotoUploadState = {
  status: '',
  progress: 0,
  currentProfileId: null,
}

export default function photoUpload(
  state: PhotoUploadState = DEFAULT_STATE,
  action: Action,
): PhotoUploadState {
  switch (action.type) {
    case actions.PREUPLOAD:
      return {
        ...state,
        status: 'preupload',
        currentProfileId: action.profileId,
      }

    case actions.STARTED:
      return { ...state, status: 'started', progress: 0 }

    case actions.SET_PROGRESS:
      return { ...state, progress: action.progress }

    case actions.COMPLETED:
      return {
        ...state,
        status: 'completed',
        progress: 100,
        photo: action.data,
      }

    case actions.UPLOAD_FAILED:
      return { ...state, status: 'failed', statusMessage: action.statusMessage }

    case actions.SAVED:
    case actions.RESET:
      return DEFAULT_STATE

    case actions.SAVING:
      return { ...state, status: 'saving' }

    case actions.SAVE_FAILED:
      return { ...state, status: 'save_failed' }

    default:
      return state
  }
}
