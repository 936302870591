import { default as styled } from 'styled-components'

import { color, font } from '~/styles/utils'

export const TextInputLabel = styled.div`
  ${font('heading')}

  float: left;
  text-transform: uppercase;

  &.disabled {
    color: ${color('mediumGrey')};
  }

  &.has-error {
    color: ${color('red')};
  }
`
