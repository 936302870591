import type { Action } from '~/actions/ui/insurance-check-modal'

export type State = {
  visible: boolean
}

const initialState = {
  visible: false,
}

const insuranceCheckModal = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case 'SHOW_INSURANCE_CHECK_MODAL':
      return {
        visible: true,
      }

    case 'HIDE_INSURANCE_CHECK_MODAL':
      return {
        visible: false,
      }

    default:
      return state
  }
}

export default insuranceCheckModal
