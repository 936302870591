import styled from 'styled-components'

import { color, font } from '~/styles/utils'

import PhoneInputV1 from './phone-input.component'

const PhoneInputV2 = styled(PhoneInputV1)<{
  hasError?: boolean
}>`
  && {
    label.label {
      ${font('demi')};
      font-size: 14px;
      line-height: 28px;

      color: ${({ hasError }) => (hasError ? color('red') : color('ikonBlue'))};
    }

    input {
      color: ${color('darkGrey')};
      background-color: ${color('lightestGrey')};
      border: 1px solid
        ${(props) => (props.hasError ? color('red') : 'transparent')};
    }

    .react-international-phone-country-selector button {
      border: 1px solid
        ${(props) => (props.hasError ? color('red') : 'transparent')};
      border-right: 1px solid ${color('darkGrey')};
      background-color: ${color('lightestGrey')};
    }
  }
`

export default PhoneInputV2
