import {
  LOAD_PROFILE_PRODUCTS_START,
  LOAD_PROFILE_PRODUCTS_SUCCESS,
  LOAD_PROFILE_PRODUCTS_FAIL,
  DEFER_PROFILE_PRODUCT,
} from '../actions/profile-products'

import type { AppActions } from '../actions/types'
import type { ProfileProduct } from '~/types'

export type ProfileProductState = {
  loading: boolean
  loaded: boolean
  data: ProfileProduct[]
}

const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function (
  state: ProfileProductState = initialState,
  action: AppActions,
) {
  switch (action.type) {
    case LOAD_PROFILE_PRODUCTS_START: {
      return { ...state, loading: true }
    }

    case LOAD_PROFILE_PRODUCTS_SUCCESS: {
      return { ...state, data: action.data, loading: false, loaded: true }
    }

    case LOAD_PROFILE_PRODUCTS_FAIL: {
      return { ...state, loading: false }
    }

    case DEFER_PROFILE_PRODUCT: {
      const updatedProfileProducts: Array<ProfileProduct> = state.data.map(
        (profileProduct) => {
          if (profileProduct.id !== action.profileProductId)
            return profileProduct
          return { ...profileProduct, passDeferred: true }
        },
      )
      return { ...state, data: updatedProfileProducts }
    }

    default: {
      return state
    }
  }
}
