export function resortChargeDeserializer(data: Record<string, any>) {
  const output = {}
  Object.keys(data).forEach((i) => {
    const member = data[i]
    output[member.profile_id] = {
      enabled: member.resort_charge.enabled,
      lastFour: member.resort_charge.last_four,
      enabledBy: member.resort_charge.enabled_by,
      creditCardId: member.resort_charge.credit_card_id,
    }
  })
  return output
}
