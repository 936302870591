import { camelizeKeys } from 'humps'

import HTTPError from '~/utils/http-error'

import { authedFetch } from '../utils/fetch'
import { reportGroupedError } from '../utils/logger'

export const MOUNTAIN_CHECKLIST_LOADED = 'MOUNTAIN_CHECKLIST_LOADED'

export function loadMountainChecklist({
  reload = false,
}: {
  reload?: boolean
} = {}) {
  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const {
      mountainChecklist: { loaded },
    } = getState()

    if (loaded && !reload) return

    try {
      const resp = await authedFetch('/api/v2/mountain-checklist')

      if (resp.ok) {
        const respJson = await resp.json()
        dispatch({
          type: MOUNTAIN_CHECKLIST_LOADED,
          data: camelizeKeys(respJson.data),
        })
      } else {
        throw new HTTPError('Unable to fetch mountain-checklist', resp, '')
      }
    } catch (error) {
      reportGroupedError('loadMountainChecklist', error)
    }
  }
}
