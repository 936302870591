/**
 * Reducer for state related to the user's product.
 */
import { PRODUCT_CATALOG_UPDATE } from '../actions/product-catalog'

import type { ProductCatalogActions } from '../actions/product-catalog'
import type { Product } from '~/types'

export type State = {
  data: Product[]
  loaded: boolean
}

// This is the initial state that will be stored in the global state tree.
const initialProductCatalogState = {
  data: [],
  loaded: false,
}
/**
 * Given the existing state and an action this computes the new state.
 *
 * @param  {object} state  The current state of the productCatalog section of
 *                         global state tree. Defaults to a initialized object.
 * @param  {object} action The action to use to compute the new state.
 * @return {object}        The new state.
 */

export default function productCatalog(
  state: State = initialProductCatalogState,
  action: ProductCatalogActions,
) {
  switch (action.type) {
    case PRODUCT_CATALOG_UPDATE:
      return { ...state, ...action.productCatalog }

    default:
      return state
  }
}
