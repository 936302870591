import styled from 'styled-components'

import { color, device } from '~/styles/utils'

export const ActionPanel = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 31px 20px;
  align-items: center;
  box-shadow: 0 4px 40px 0 rgba(0, 0, 0, 0.25);
  background-color: ${color('lightestGrey')};

  @media ${device('notMobile')} {
    padding: 31px 50px;
  }
`
