import { RESET_FEATURE_FLAGS, SET_FEATURE_FLAGS } from '~/actions/feature-flags'
import { featureFlags } from '~/config/feature-flags'

import type { FeatureFlagsActions } from '~/actions/feature-flags'
import type { FeatureFlags } from '~/types'

const initialState = { ...featureFlags }

export type { FeatureFlags }

export default function (
  state: FeatureFlags = initialState,
  action: FeatureFlagsActions,
) {
  switch (action.type) {
    case SET_FEATURE_FLAGS:
      return { ...state, ...action.payload }

    case RESET_FEATURE_FLAGS:
      return { ...initialState }

    default:
      return state
  }
}
