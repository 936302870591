import {
  NAVIGATION_PUSH,
  NAVIGATION_REPLACE,
  NAVIGATION_BACK,
} from '~/actions/navigation'

import type { AppActions } from '~/actions/types'

export type NavigationState = {
  url: string | null | undefined
  action: string | null | undefined
}

const initialState = {
  url: null,
  action: null,
}

export default (state: NavigationState = initialState, action: AppActions) => {
  switch (action.type) {
    case NAVIGATION_PUSH: {
      return { ...state, url: action.payload.url, action: 'PUSH' }
    }

    case NAVIGATION_REPLACE: {
      return { ...state, url: action.payload.url, action: 'REPLACE' }
    }

    case NAVIGATION_BACK: {
      return { ...state, url: null, action: 'BACK' }
    }

    default:
      return state
  }
}
