/**
 * Defines actions and action creators related to the product catalog
 */
import { addFlashMessage } from '~/actions/flash-message'
import { i18n } from '~/i18n'

import { requestCatalog } from '../utils/api'
import { reportGroupedError } from '../utils/logger'

import type { AppState } from '../reducers'
import type { Currency } from '~/types'

export type ProductCatalogActions = {
  type: 'PRODUCT_CATALOG_UPDATE'
  productCatalog: Record<string, any>
}

type Dispatch = (...args: Array<any>) => any
type GetState = () => AppState //
// - Actions and Sync Action Creators
//

export const PRODUCT_CATALOG_UPDATE = 'PRODUCT_CATALOG_UPDATE'
export function productCatalogUpdate(productCatalog: Record<string, any>) {
  return {
    type: PRODUCT_CATALOG_UPDATE,
    productCatalog,
  }
}

function productCatalogLoaded(productCatalog) {
  return productCatalogUpdate({ ...productCatalog, loaded: true })
}

//
// - Async Action Creators
//

/**
 * loadProductCatalog makes a request to the server for the product catalog
 *
 * Actions dispatched:
 *  Product Catalog information fetched  -> PRODUCT_CATALOG
 *
 * @return {Function}  Thunk which will initiate the request to the server.
 */
let fetching = false
export function loadProductCatalog(currency?: Currency) {
  return (dispatch: Dispatch, getState: GetState) => {
    const { cart, productCatalog } = getState()
    if (fetching || productCatalog.loaded) return
    fetching = true
    return requestCatalog(currency || cart.payloadCurrency)
      .then((catalog) => {
        fetching = false
        dispatch(productCatalogLoaded(catalog))
      })
      .catch((error) => {
        fetching = false
        reportGroupedError('loadProductCatalog', error)
        dispatch(
          addFlashMessage(
            'error',
            i18n.t('components.flash_messages.product_catalog_error'),
            {
              reload: true,
            },
          ),
        )
      })
  }
}
