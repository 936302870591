import { decamelizeKeys } from 'humps'

export const serializeReservationCart = ({
  termsConditionsAcceptedAt,
}: {
  termsConditionsAcceptedAt: Date | null
}) =>
  decamelizeKeys({
    data: {
      termsConditionsAcceptedAt,
    },
  })
