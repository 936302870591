import { profileCreateGuestSerializer } from '../../serializers/profile'
import { authedFetch } from '../../utils/fetch'

import type { CreateGuestProfile } from '~/types'

export type GuestSource = 'shop' | 'Central-RTP' | 'voucher_claim'

const createGuestURL = (source: string) => `/api/v2/guests?source=${source}`

export function createGuest(profileInfo: CreateGuestProfile, source: string) {
  const options = {
    method: 'POST',
    body: JSON.stringify(profileCreateGuestSerializer(profileInfo)),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }

  return authedFetch(createGuestURL(source), options)
}
