export type CreditCardType = 'discover' | 'visa' | 'american_express' | 'master'
export type ApiTokenizedCreditCard = {
  id: number
  month: string
  year: string
  name_on_card: string
  last_four_digits: string
  card_type: CreditCardType
}

export function creditCardDeserializer(data: ApiTokenizedCreditCard) {
  const {
    id,
    month,
    year,
    last_four_digits: lastFourDigits,
    name_on_card: nameOnCard,
    card_type: cardType,
  } = data

  return {
    id,
    month,
    year,
    lastFourDigits,
    nameOnCard,
    cardType,
  }
}
