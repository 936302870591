import { PhoneInput } from 'react-international-phone'
import styled from 'styled-components'

import { color, font } from '~/styles/utils'

export const Label = styled.label`
  ${font('bold')};
`

export const ReactInternationalPhoneInput = styled(PhoneInput)<{
  $hasError?: boolean
}>`
  && {
    width: 100%;
    display: flex;

    input {
      height: 50px;
      border: 1px solid
        ${({ $hasError }) => color($hasError ? 'red' : 'darkGrey')};
      border-radius: 0;
      font-size: 16px;
      flex: 1;
      color: ${({ $hasError }) => color($hasError ? 'red' : 'ikonBlue')};
      padding: 16px 10px;
    }

    .react-international-phone-country-selector button {
      height: 50px;
      border: 1px solid
        ${({ $hasError }) => color($hasError ? 'red' : 'darkGrey')};
      border-radius: 0;
      width: 96px;

      li span {
        font-size: 16px;
      }
    }

    .react-international-phone-country-selector-button__dropdown-arrow {
      border: none;
      font-weight: normal;
      font-size: 14px;
      margin: 0 0 0 6px;
      display: flex;
      transform: none;

      &::before {
        font-family: 'icomoon' !important;
        content: '\\e909';
        color: #007db7;
      }
    }
  }
`
