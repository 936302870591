import deserializeCart from '~/deserializers/cart'
import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import { updateCart } from './update-cart'

type Dispatch = (...args: Array<any>) => any

export function loadCart() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await authedFetch('/api/v2/cart')

      if (response.status === 200) {
        const cart = deserializeCart(await response.json())
        dispatch(updateCart(cart))
      } else {
        throw new HTTPError(
          `Unable to fetch cart`,
          response,
          await response.text(),
        )
      }
    } catch (error) {
      reportGroupedError('loadCart', error)
    }
  }
}
