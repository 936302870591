import { serializeAddress } from '~/serializers/address'
import HTTPError from '~/utils/http-error'

import { authedFetch } from '../fetch'

import type { Address } from '~/types'

export async function requestPutAddresses(
  profileId: string,
  addresses: Address[],
): Promise<Response | null | undefined> {
  const path = `/api/v2/profiles/${profileId}/addresses`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    body: JSON.stringify({
      data: addresses.map(serializeAddress),
    }),
  }

  const resp = await authedFetch(path, options)

  if (resp.ok) return resp

  throw new HTTPError(`failed: PATCH ${path}`, resp, await resp.text())
}

export async function requestVerifyAddress(
  profileId: string,
  address: Address,
): Promise<Response> {
  const path = `/api/v2/profiles/${profileId}/addresses/verify`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify({
      data: serializeAddress(address),
    }),
  }

  const resp = await authedFetch(path, options)
  if (resp.ok) return resp

  throw new HTTPError(`failed: POST ${path}`, resp, await resp.text())
}
