/**
 * Reducer for state related to flash messages.
 */

import { NAVIGATION_LOCATION_CHANGE_ACTION_TYPE } from '~/hooks/use-location-change-handler'

import type { FlashMessage } from '../actions/flash-message'
import type { AppActions } from '~/actions/types'

function decrementNavCount(msg: FlashMessage): FlashMessage {
  if (typeof msg.options.navCount === 'number') {
    return {
      ...msg,
      options: { ...msg.options, navCount: msg.options.navCount - 1 },
    }
  }

  return msg
}

function validMsgs({ options: { navCount } }) {
  return navCount === undefined || navCount >= 0
}

const initialState = []

export default function flashMessages(
  state: FlashMessage[] = initialState,
  action: AppActions,
): FlashMessage[] {
  switch (action.type) {
    case 'REMOVE_FLASH_MESSAGE': {
      const index = state.indexOf(action.msg)

      // An exclusive flash message requests that it be the only flash message that display.
      // We remove all other flash messages when this one is dismissed.
      if (state[index]?.options?.exclusive === true) return []

      return [...state.slice(0, index), ...state.slice(index + 1)]
    }

    case 'ADD_FLASH_MESSAGE': {
      const filter_by_id = action?.msg?.options?.id
      const existing_messages = filter_by_id
        ? state.filter(({ options: { id } }) => id !== filter_by_id)
        : state

      return [...existing_messages, action.msg]
    }

    case NAVIGATION_LOCATION_CHANGE_ACTION_TYPE:
      // @ts-expect-error TS2769
      return state.map(decrementNavCount).filter(validMsgs)

    default:
      return state
  }
}
