import { push } from '~/actions/navigation'
import { i18n } from '~/i18n'
import { getErrorMessage } from '~/utils/credit-card'

import { reportGroupedError } from '../../utils/logger'
import { setCartCreditCard } from '../cart/credit-card'

import type { PaymentData } from '~/types'

type Dispatch = (...args: Array<any>) => any

const REVIEW_PATH = '/payment/review'

export const START_SET_CART_CC = 'payment/START_SET_CART_CC'
export const SET_CART_CC_FAILED = 'payment/SET_CART_CC_FAILED'
export const SET_CART_CC_SUCCESS = 'payment/SET_CART_CC_SUCCESS'

export type PaymentUIActions =
  | {
      type: 'payment/START_SET_CART_CC'
    }
  | {
      type: 'payment/SET_CART_CC_FAILED'
      errorMessage: string
    }
  | {
      type: 'payment/SET_CART_CC_SUCCESS'
    }

export function cartCreditCardErrorOccurred(errorMessage: string) {
  return {
    type: SET_CART_CC_FAILED,
    errorMessage,
  }
}

export function proceedToReview(
  paymentDetails: PaymentData,
  reCaptchaToken?: string,
) {
  return async (dispatch: Dispatch) => {
    let errorOccurred = false
    const { creditCard, saveCardOnFile } = paymentDetails
    dispatch({
      type: START_SET_CART_CC,
    })

    try {
      await dispatch(
        setCartCreditCard({
          paymentData: {
            creditCard: { ...creditCard, reCaptchaToken },
            saveCardOnFile: saveCardOnFile,
          },
          onResponseErrors: (errors: Record<string, any>) => {
            errorOccurred = true
            dispatch(cartCreditCardErrorOccurred(getErrorMessage(errors)))
          },
        }),
      )

      if (!errorOccurred) {
        dispatch({
          type: SET_CART_CC_SUCCESS,
        })
      }
    } catch (error) {
      errorOccurred = true
      dispatch({
        type: SET_CART_CC_FAILED,
        errorMessage: i18n.t('pages.payment.credit_card.tokenization_failed'),
      })
      reportGroupedError('payment/proceedToReview', error)
    }

    if (!errorOccurred) {
      dispatch(push(REVIEW_PATH))
    }
  }
}
