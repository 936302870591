import { decamelizeKeys } from 'humps'

import type { VoucherShareRequest } from '~/types'

export const serializeVoucherShare = ({
  recipientName,
  recipientEmail,
  shareQuantity,
  note,
  profileProductId,
}: VoucherShareRequest) =>
  decamelizeKeys({
    data: {
      recipientName,
      recipientEmail,
      shareQuantity,
      note,
      profileProductId,
    },
  })
