import type { CartState } from '~/types'

export type UpdateCartAction = {
  type: 'UPDATE_CART'
  cart: CartState
}
export const UPDATE_CART = 'UPDATE_CART' as const
export function updateCart(cart: CartState) {
  return {
    type: UPDATE_CART,
    cart,
  }
}
