import { css } from 'styled-components'

const base = css`
  font-family: 'futura-pt', sans-serif;
  font-weight: normal;
`
const bold = css`
  font-family: 'futura-pt-bold', sans-serif;
  font-weight: bold;
`

const demi = css`
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
  font-style: normal;
`

const heading = bold

export default {
  base,
  bold,
  demi,
  heading,
}
