import keyBy from 'lodash/keyBy'

import {
  LOAD_RESORTS_FAILURE,
  LOAD_RESORTS_REQUEST,
  LOAD_RESORTS_SUCCESS,
} from '~/actions/resorts'

import type { Resort } from '~/types'

export type ResortsState = {
  data: Resort[]
  loading: boolean
  errors: Record<string, any>
  byCode: Record<string, Resort>
  byId: Record<number, Resort>
}

const initialResortsState = {
  data: [],
  loading: false,
  errors: {},
  byCode: {},
  byId: {},
}

const getById = (data: Resort[]): Record<number, Resort> => {
  return keyBy(data, 'id')
}

export default function resorts(
  state: ResortsState = initialResortsState,
  action: Record<string, any>,
): ResortsState {
  switch (action.type) {
    case LOAD_RESORTS_REQUEST:
      return { ...state, loading: true }

    case LOAD_RESORTS_FAILURE:
      return { ...state, loading: false, errors: action.errors }

    case LOAD_RESORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {},
        data: action.data,
        byCode: action.byCode,
        byId: getById(action.data),
      }

    default:
      return state
  }
}
