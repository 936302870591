import Cookies from 'js-cookie'
import md5 from 'md5'
import { createSelector } from 'reselect'

import type { LoginState } from './types'
import type { GuestProfile } from '~/types'

const getEmail = (profile: GuestProfile) => profile.email

const getLoginSystem = () => (Cookies.get('login-via-auth0') ? 'Auth0' : 'AMP')

// Using reselect for caching.
// It's probably not a good idea to hash every page view.
export default createSelector(
  getEmail,
  getLoginSystem,
  (email, loginSystem): LoginState => {
    if (email) {
      return {
        logInState: 'logged-in',
        heid: md5(email),
        loginSystem,
      }
    }

    return {
      logInState: 'logged-out',
    }
  },
)
