export function inputReplace(item: string | any) {
  // Replace `&` with `and`
  if (typeof item === 'string') {
    return item.replace('&', 'and')
  } else {
    return item
  }
}

export function inputReplaceMap(
  data: Record<string, any>,
): Record<string, any> {
  return map(data, (item) => inputReplace(item))
}

function map(data, func) {
  return Object.keys(data).reduce(function (previous, current) {
    previous[current] = func(data[current])
    return previous
  }, {})
}
