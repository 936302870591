/**
 * Reducer for state related to the UI of the cart.
 */
import { combineReducers } from 'redux'

import {
  SET_WAIVER_SIGNED_DATE_STATUS,
  UPDATE_CART_FAILURE_MESSAGE,
  UPDATE_CART_STATUS,
  START_UPDATE_PROMO_CODE,
  STOP_UPDATE_PROMO_CODE,
  START_UPDATE_PASS_ASSIGNMENT,
  STOP_UPDATE_PASS_ASSIGNMENT,
} from '~/actions/ui/cart'

function status(state = '', action) {
  switch (action.type) {
    case UPDATE_CART_STATUS:
      return action.state

    default:
      return state
  }
}

function failureMessage(state = '', action) {
  switch (action.type) {
    case UPDATE_CART_FAILURE_MESSAGE:
      return action.state

    default:
      return state
  }
}

function signWaiverStatus(state = '', action) {
  switch (action.type) {
    case SET_WAIVER_SIGNED_DATE_STATUS:
      return action.state

    default:
      return state
  }
}

function promoCodeUpdating(state = false, action) {
  switch (action.type) {
    case START_UPDATE_PROMO_CODE:
      return true

    case STOP_UPDATE_PROMO_CODE:
      return false

    default:
      return state
  }
}

function passAssignmentUpdating(state = false, action) {
  switch (action.type) {
    case START_UPDATE_PASS_ASSIGNMENT:
      return true

    case STOP_UPDATE_PASS_ASSIGNMENT:
      return false

    default:
      return state
  }
}

export default combineReducers<any>({
  failureMessage,
  signWaiverStatus,
  status,
  promoCodeUpdating,
  passAssignmentUpdating,
})
