import { css } from 'styled-components'

const reset = css`
  a {
    color: inherit;
    text-decoration: none;
  }

  select {
    color: inherit;
    font-size: inherit;
  }
`

export default reset
