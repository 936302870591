import { creditCardSerializer } from '../../serializers/credit-card'
import { authedFetch } from '../fetch'

import type { PaymentData, Currency } from '~/types'

const CART_CC_PATH = '/api/v2/cart/credit-card'

export function requestSetCartCreditCard(paymentData: PaymentData) {
  const opts = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify(creditCardSerializer(paymentData)),
  }
  return authedFetch(CART_CC_PATH, opts)
}

type UpdateCartDataParams = {
  payload_currency?: Currency
  affirm_checkout_id?: string
  foundation_donation?: number
}

export function requestUpdateCart(dataParams: UpdateCartDataParams) {
  const path = '/api/v2/cart'
  const opts = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      data: dataParams,
    }),
  }
  return authedFetch(path, opts)
}

export function postCartRenew() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({}),
  }

  return authedFetch(`/api/v2/cart/renew`, options)
}
