import { profileV2Deserializer } from './profile'
import { renewalProductDeserializer } from './renewal-product'

import type { API$Group, GroupMember } from '~/types'

export function groupDeserializer(data: API$Group): GroupMember[] {
  return data.group_members.map((member) => {
    const output = {
      ...profileV2Deserializer(member.profile),
      isAdmin: member.is_admin,
      isGuest: member.is_guest,
      isParticipant: member.is_participant,
      isActiveParticipant: member.is_active_participant,
      isRemovableFromGroup: member.is_removable_from_group,
      pendingInvite: member.pending_invite,
    } as GroupMember

    if (member.renewal_products) {
      output.renewalProducts = member.renewal_products.map(
        renewalProductDeserializer,
      )
    }

    return output
  })
}
