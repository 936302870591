/**
 * Defines actions and action creators related to resort charge.
 */
import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

import { resortChargeDeserializer } from '../deserializers/resort-charge'

type Dispatch = (...args: Array<any>) => any

export type ResortChargeActions = {
  type: 'RESORT_CHARGE_LOADED'
  loaded: boolean
  data: Record<string, any>
}

//
// - Actions and Sync Action Creators
//
export const RESORT_CHARGE_LOADED = 'RESORT_CHARGE_LOADED'

export function resortChargeLoaded(groupMembers) {
  return {
    type: RESORT_CHARGE_LOADED,
    loaded: true,
    data: groupMembers,
  }
}

//
// - Async Action Creators
//
export function loadResortCharge({
  reload = false,
}: {
  reload?: boolean
} = {}) {
  return (dispatch: Dispatch, getState: (...args: Array<any>) => any) => {
    const {
      resortCharge: { loaded },
    } = getState()

    if (loaded && !reload) return

    authedFetch('/api/v2/resort-charge')
      .then((resp) => {
        if (resp.ok) {
          return resp.json().then(({ data }) => {
            dispatch(resortChargeLoaded(resortChargeDeserializer(data)))
          })
        } else {
          return Promise.reject(
            new HTTPError('Unable to fetch resort charge', resp, ''),
          )
        }
      })
      .catch((error) => {
        reportGroupedError('loadResortCharge', error)
      })
  }
}

let updating = false
export function updateResortCharge(profileId: string, enabled: boolean) {
  return async (dispatch: Dispatch) => {
    if (updating) return
    updating = true
    const body = JSON.stringify({
      data: {
        enabled: enabled,
      },
    })
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body,
    }
    const path = `/api/v2/resort-charge/${profileId}`

    try {
      const resp = await authedFetch(path, options, '/create-my-account')
      updating = false

      if (resp.ok) {
        await dispatch(
          loadResortCharge({
            reload: true,
          }),
        )
      } else {
        const rawBody = await resp.text()
        throw new HTTPError('resort charge update failed', resp, rawBody)
      }
    } catch (error) {
      reportGroupedError('updateResortCharge', error)
      updating = false
    }
  }
}
