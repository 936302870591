import { creditCardDeserializer } from '~/deserializers/credit-card'
import getCustomerAPI from '~/utils/api/get-customer-api'
import { reportGroupedError } from '~/utils/logger'

import type { TokenizedCreditCard } from '~/types'

export type CreditCardOnFileActions =
  | {
      type: 'creditCardOnFile/LOAD_START'
    }
  | {
      type: 'creditCardOnFile/LOAD_SUCCESS'
      data: TokenizedCreditCard
    }
  | {
      type: 'creditCardOnFile/LOAD_NO_CARD'
    }
  | {
      type: 'creditCardOnFile/LOAD_FAIL'
    }

export const LOAD_CREDIT_CARD_ON_FILE_START = 'creditCardOnFile/LOAD_START'
export const LOAD_CREDIT_CARD_ON_FILE_SUCCESS = 'creditCardOnFile/LOAD_SUCCESS'
export const LOAD_CREDIT_CARD_ON_FILE_NO_CARD = 'creditCardOnFile/LOAD_NO_CARD'
export const LOAD_CREDIT_CARD_ON_FILE_FAIL = 'creditCardOnFile/LOAD_FAIL'

export function loadCreditCardOnFile({
  reload = false,
}: {
  reload?: boolean
} = {}) {
  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const api = getCustomerAPI()
    const {
      creditCardOnFile: { loaded, loading },
    } = getState()

    if (loading || (loaded && !reload)) return

    dispatch({
      type: LOAD_CREDIT_CARD_ON_FILE_START,
    })

    try {
      const response = await api.getCreditCardOnFile()

      if (response.ok) {
        const { data } = await response.json()
        dispatch({
          type: LOAD_CREDIT_CARD_ON_FILE_SUCCESS,
          data: creditCardDeserializer(data),
        })
      } else if (response.status === 404) {
        dispatch({
          type: LOAD_CREDIT_CARD_ON_FILE_NO_CARD,
        })
      } else {
        dispatch({
          type: LOAD_CREDIT_CARD_ON_FILE_FAIL,
        })
      }
    } catch (error) {
      reportGroupedError('loadCreditCardOnFile', error)
      dispatch({
        type: LOAD_CREDIT_CARD_ON_FILE_FAIL,
      })
    }
  }
}
