import { handleRequestFailure } from '~/actions/request'
import { mapByKey } from '~/utils/array'

import resortDeserializer from '../deserializers/resort'
import { requestResorts } from '../utils/api/resorts'
import { reportGroupedError } from '../utils/logger'

import type { Resort } from '~/types'

export const LOAD_RESORTS_REQUEST = 'LOAD_RESORTS_REQUEST'
export const LOAD_RESORTS_SUCCESS = 'LOAD_RESORTS_SUCCESS'
export const LOAD_RESORTS_FAILURE = 'LOAD_RESORTS_FAILURE'

export type ResortsActions =
  | {
      type: 'LOAD_RESORTS_REQUEST'
    }
  | {
      type: 'LOAD_RESORTS_SUCCESS'
      data: Resort[]
    }
  | {
      type: 'LOAD_RESORTS_FAILURE'
    }

function handleFailure(dispatch: (...args: Array<any>) => any) {
  dispatch(handleRequestFailure())
  return dispatch({
    type: LOAD_RESORTS_FAILURE,
  })
}

export function loadResorts({
  reload = false,
}: {
  reload?: boolean
} = {}) {
  return async (
    dispatch: (...args: Array<any>) => any,
    getState: (...args: Array<any>) => any,
  ) => {
    const {
      resorts: { data, loading },
    } = getState()

    const loaded = data.length > 0
    if (loading || (loaded && !reload)) return

    dispatch({
      type: LOAD_RESORTS_REQUEST,
    })

    try {
      const { data, errors } = await requestResorts()

      if (errors.length > 0) return handleFailure(dispatch)

      const deserialized = data.map((resort) => resortDeserializer(resort))

      return dispatch({
        type: LOAD_RESORTS_SUCCESS,
        data: deserialized,
        byCode: mapByKey('code')(deserialized),
      })
    } catch (err) {
      reportGroupedError('loadResorts', err)
      return handleFailure(dispatch)
    }
  }
}
