export const SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS'
export const RESET_FEATURE_FLAGS = 'RESET_FEATURE_FLAGS'

type FeatureFlagsPayload = Record<string, boolean>
export type FeatureFlagsActions =
  | {
      type: 'SET_FEATURE_FLAGS'
      payload: FeatureFlagsPayload
    }
  | {
      type: 'RESET_FEATURE_FLAGS'
    }

export function setFeatureFlags(flags: FeatureFlagsPayload) {
  return {
    type: SET_FEATURE_FLAGS,
    payload: flags,
  }
}

export function resetFeatureFlags() {
  return {
    type: RESET_FEATURE_FLAGS,
  }
}
