/**
 * Defines actions and action creators related to the user's addresses.
 */
import { addFlashMessage } from '~/actions/flash-message'
import { i18n } from '~/i18n'
import delay from '~/utils/delay'

import { loadProfile } from './profile'
import { requestPutAddresses } from '../utils/api/address'
import { reportGroupedError } from '../utils/logger'

import type { Address } from '~/types'

type Dispatch = (...args: Array<any>) => any

export const UPDATE_ADDRESSES_START = 'address/UPDATE_START'
export const UPDATE_ADDRESSES_SUCCESS = 'address/UPDATE_SUCCESS'
export const UPDATE_ADDRESSES_FAIL = 'address/UPDATE_FAIL'

export type AddressActions =
  | {
      type: 'address/UPDATE_START'
    }
  | {
      type: 'address/UPDATE_SUCCESS'
    }
  | {
      type: 'address/UPDATE_FAIL'
    }

//
// - Async Action Creators
//

/**
 * updateAddresses makes a request to the server to update the profile addresses.
 *
 * @return {Function}  Thunk which will initiate the request to the server.
 */
export function updateAddresses(
  profileId: string,
  addresses: Address[],
  showSuccessFlash = true,
) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_ADDRESSES_START,
    })

    try {
      await requestPutAddresses(profileId, addresses)

      await delay()

      dispatch({
        type: UPDATE_ADDRESSES_SUCCESS,
      })

      if (showSuccessFlash) {
        dispatch(
          addFlashMessage(
            'info',
            i18n.t('components.flash_messages.address_updated'),
            {
              navCount: 0,
              glyph: 'info',
            },
          ),
        )
      }

      dispatch(
        loadProfile({
          reload: true,
        }),
      )
      return true
    } catch (error) {
      dispatch({
        type: UPDATE_ADDRESSES_FAIL,
      })
      dispatch(
        addFlashMessage(
          'error',
          i18n.t('components.flash_messages.address_update_error'),
        ),
      )
      reportGroupedError('updateAddresses', error)
      return false
    }
  }
}
