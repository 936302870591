import type { NewCreditCard, PaymentData } from '~/types'

type CreditCardCreateBody = {
  data: {
    number: NewCreditCard['number']
    month: NewCreditCard['month']
    year: NewCreditCard['year']
    cvc: NewCreditCard['cvc']
    name_on_card: NewCreditCard['nameOnCard']
  }
  meta?: {
    save_on_file?: PaymentData['saveCardOnFile']
  }
  admin_acting_as_profile_id?: string
}

export function creditCardSerializer(
  paymentData: PaymentData,
  adminActingAsProfileId?: string,
): CreditCardCreateBody {
  const { creditCard, saveCardOnFile } = paymentData
  const { number, month, year, cvc, nameOnCard, reCaptchaToken } = creditCard

  const body: CreditCardCreateBody = {
    data: {
      number,
      month,
      year,
      cvc,
      name_on_card: nameOnCard,
      // @ts-expect-error TS2322
      recaptcha_token: reCaptchaToken,
    },
  }

  if (saveCardOnFile !== undefined) {
    body.meta = {
      save_on_file: saveCardOnFile,
    }
  }

  if (adminActingAsProfileId) {
    body.admin_acting_as_profile_id = adminActingAsProfileId
  }

  return body
}
