import {
  SHOW_AVAILABLE_VOUCHERS_MODAL,
  HIDE_AVAILABLE_VOUCHERS_MODAL,
} from '~/actions/ui/available-vouchers-modal'

import type { AppActions } from '~/actions/types'

const initialState = {
  visible: false,
}

export type State = {
  visible: boolean
}

export default (state: State = initialState, action: AppActions): State => {
  switch (action.type) {
    case SHOW_AVAILABLE_VOUCHERS_MODAL:
      return {
        visible: true,
      }

    case HIDE_AVAILABLE_VOUCHERS_MODAL:
      return {
        visible: false,
      }

    default:
      return state
  }
}
