import type { AppActions } from '../actions/types'
import type { SingleUseCredit } from '~/types'

export type CreditsState = {
  loading: boolean
  loaded: boolean
  data: SingleUseCredit[]
}

const initialState = {
  loading: false,
  loaded: false,
  data: [],
}

export default function (
  state: CreditsState = initialState,
  action: AppActions,
) {
  switch (action.type) {
    case 'credits/LOAD_START':
      return { ...state, loading: true }

    case 'credits/LOAD_SUCCESS':
      return { ...state, loading: false, loaded: true, data: action.data }

    case 'credits/LOAD_FAIL':
      return { ...state, loading: false }

    default:
      return state
  }
}
