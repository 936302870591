import { ExtendableError } from 'ts-error'
/**
 * Handles capturing information about an HTTP error with some context.
 *
 * @param {String} message The message associated with the failed request.
 * @param {Object} response Response the defined the error.
 * @param {String} text Response text body to describe the error.
 */

type context = {
  text: string
  status?: number
  url?: string
  requestID?: string
  error?: string
}
export default class HTTPError extends ExtendableError {
  context: context = { text: '' }

  constructor(
    message: string,
    response: Response | null | undefined,
    text: string,
  ) {
    super(message)
    this.context = {
      text,
    }

    if (response) {
      this.context = {
        text: this.context.text,
        status: response.status,
        url: response.url,
      }

      try {
        const headerValue = response.headers.get('x-request-id')

        if (headerValue != null) {
          this.context.requestID = headerValue
        }
      } catch (e) {
        this.context.error = e.toString()
      }
    }
  }
}
