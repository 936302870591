import type { CartState, AnalyticsProduct } from '../types'

function serializePurchase(cart: CartState, orderId: number) {
  return {
    mbox: 'orderConfirmPage',
    params: {
      orderId: orderId.toString(),
      orderTotal: cart.total.toString(),
      productPurchasedId: serializeProducts(cart.analytics.products),
      cartCreatedAt: cart.createdAt,
    },
  }
}

function serializeProducts(products: AnalyticsProduct[]) {
  return products.map((p) => p.id).join()
}

export default {
  serializePurchase,
}
