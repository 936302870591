import { addFlashMessage } from '~/actions/flash-message'
import { i18n } from '~/i18n'

export function handleRequestFailure() {
  return addFlashMessage(
    'error',
    i18n.t('components.flash_messages.generic_request_failure'),
    {
      id: 'request-failure',
    },
  )
}
