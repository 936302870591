// Any z-index values used in the application should belong here:
export const background = -1
export const base = 0
export const foreground = base + 1000
export const modals = 6000

export default {
  background,
  base,
  foreground,
  modals,
}
