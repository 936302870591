/**
 * Component to handle text entry.
 */
import classNames from 'classnames'
import React, { Component } from 'react'

import Icon from '~/components/icon'
import TextInputLabel from '~/components/text-input-label'
import TextInputSublabel from '~/components/text-input-sublabel'

import './text-input.scss'

import type { Glyphs } from '../icon'
import type { ReactNode } from 'react'

export type Props = {
  disabled?: boolean
  glyph?: Glyphs
  onChange: (...args: Array<any>) => any
  onBlur?: (...args: Array<any>) => any
  hasError?: boolean
  label?: ReactNode
  sublabel?: ReactNode
  name?: string
  value?: string
  defaultValue?: string
  id?: string
  title?: string
  autoComplete?: string
  type?: 'text' | 'password' | 'email' | 'tel' | 'datetime-local' | 'time'
  className?: string
  placeholder?: string
  children?: ReactNode
  multiline?: boolean
  onClick?: () => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  readOnly?: boolean
  maxLength?: number
  minLength?: number
  inputRef?:
    | React.MutableRefObject<HTMLInputElement>
    | React.MutableRefObject<HTMLTextAreaElement>
}

type State = {
  focused: boolean
}

export default class TextInput extends Component<Props, State> {
  static defaultProps = {
    type: 'text' as const,
  }

  state = {
    focused: false,
  }

  handleBlur = (event: React.SyntheticEvent) => {
    this.setState({
      focused: false,
    })
    const blurHandler = this.props.onBlur || this.props.onChange

    if (blurHandler) blurHandler(event)
  }

  handleFocus = () => {
    this.setState({
      focused: true,
    })
  }

  handleLabelClick = (event: React.SyntheticEvent) => {
    const targetIsInput =
      event.target instanceof Element && event.target.nodeName === 'INPUT'

    if (this.state.focused && !targetIsInput) {
      event.preventDefault()
    }
  }

  get fieldType() {
    return this.props.multiline ? 'textarea' : 'input'
  }

  render() {
    const {
      autoComplete,
      className,
      disabled,
      glyph,
      hasError,
      id,
      label,
      name,
      onChange,
      placeholder,
      sublabel,
      title,
      type,
      value,
      defaultValue,
      onClick,
      onKeyDown,
      readOnly,
      maxLength,
      minLength,
      inputRef,
    } = this.props
    const fieldClass = classNames('amp-text-input', className, {
      'has-error': hasError,
      disabled,
    })
    const FieldElement = this.fieldType
    return (
      <label
        htmlFor={id}
        className={fieldClass}
        onMouseDown={this.handleLabelClick}
      >
        <TextInputLabel disabled={disabled} hasError={hasError}>
          {label}
        </TextInputLabel>
        <TextInputSublabel>{sublabel}</TextInputSublabel>
        <div
          className={classNames('text-wrapper', {
            focused: this.state.focused,
          })}
        >
          {glyph && <Icon glyph={glyph} />}
          <FieldElement
            onClick={onClick}
            autoComplete={autoComplete}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={this.handleBlur}
            onChange={onChange}
            onFocus={this.handleFocus}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            title={title}
            type={type}
            value={value}
            defaultValue={defaultValue}
            readOnly={readOnly}
            maxLength={maxLength}
            minLength={minLength}
            // @ts-expect-error the ref type is determined by the fieldType
            ref={inputRef}
          />
        </div>
        {this.props.children}
      </label>
    )
  }
}
