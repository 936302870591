import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { pathnameIncludesLocale } from '~/utils/locale'

export const NAVIGATION_LOCATION_CHANGE_ACTION_TYPE = '@@router/LOCATION_CHANGE'

export default () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (pathnameIncludesLocale(location.pathname)) return

    dispatch({
      type: NAVIGATION_LOCATION_CHANGE_ACTION_TYPE,
      payload: {
        location,
      },
    })
  }, [location])

  return null
}
