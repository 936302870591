import type { CloudinaryPhoto } from '~/types'

export default function serializeCloudinaryPhoto(photo: CloudinaryPhoto) {
  return {
    data: {
      uploaded_at: photo.uploadedAt || '',
      public_id: photo.publicId || '',
      width: photo.width || 0,
      height: photo.height || 0,
      version: photo.version || '',
      faces: photo.faces || '',
    },
  }
}
