export const TOGGLE_MINOR_CONSENT = 'minorConsent/toggle'
export const ALERT_MINOR_CONSENT = 'minorConsent/alert'
export const RESET_MINOR_CONSENT = 'minorConsent/reset'

type toggleMinorConsent = {
  type: 'minorConsent/toggle'
}
type alertMinorConsent = {
  type: 'minorConsent/alert'
  value: boolean
}
type resetMinorConsent = {
  type: 'minorConsent/reset'
}

export const toggleMinorConsent = (): toggleMinorConsent => ({
  type: TOGGLE_MINOR_CONSENT,
})
export const alertMinorConsent = (value: boolean): alertMinorConsent => ({
  type: ALERT_MINOR_CONSENT,
  value,
})
export const resetMinorConsent = (): resetMinorConsent => ({
  type: RESET_MINOR_CONSENT,
})

export type MinorConsentActions =
  | toggleMinorConsent
  | alertMinorConsent
  | resetMinorConsent
