import type { CreateGuestProfile, ProfileDetailsEdit } from '~/types'

type SerializedProfileData = {
  email: string
  first_name: string
  last_name: string
  dob: string
  gender?: string
  notify_account_holder?: boolean
}

type SerializedCreateGuestData = SerializedProfileData & {
  password?: string
  profile_id?: string
  registration_token?: string
  onboarding_token?: string
}

export function profileCreateGuestSerializer(profile: CreateGuestProfile) {
  const profileData: SerializedCreateGuestData = {
    email: profile.email,
    password: profile.password,
    first_name: profile.firstName,
    last_name: profile.lastName,
    dob: profile.dob,
  }

  if (profile.id) {
    profileData.profile_id = profile.id
  }

  if (profile.registrationToken) {
    profileData.registration_token = profile.registrationToken
  }

  if (profile.onboardingToken) {
    profileData.onboarding_token = profile.onboardingToken
  }

  return {
    data: profileData,
  }
}

export function serializeProfile(profile: Partial<ProfileDetailsEdit>) {
  const profileData: SerializedProfileData = {
    email: profile.email,
    first_name: profile.firstName,
    last_name: profile.lastName,
    dob: profile.dob,
    notify_account_holder: !!profile.notifyAccountHolder,
  }

  if (profile.gender) {
    profileData.gender = profile.gender
  }

  return {
    data: profileData,
  }
}
