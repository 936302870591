import classNames from 'classnames'
import { decamelize } from 'humps'
import React, { Component } from 'react'

import './form.scss'
import Button from '~/components/button'
import CheckboxInput from '~/components/checkbox-input'
import DateInput from '~/components/date-input'
import ErrorMessage from '~/components/error-message'
import FileInput from '~/components/file-input'
import Password from '~/components/password'
import PasswordInput from '~/components/password-input'
import PhoneInput from '~/components/phone-input'
import SelectInput from '~/components/select-input'
import TextInput from '~/components/text-input'
import { i18n } from '~/i18n'
import { scrollToElement } from '~/utils/scrolling'
import { humanize } from '~/utils/string'

import { controlName, submitBtnText, submitBtnType } from './form.utils'

import type { FieldKey, InputValue, Label, Options, Props } from './form.types'
import type { ReactNode } from 'react'
import type { Option as SelectInputOption } from '~/components/select-input'

export default class Form extends Component<Props> {
  errorRefs: Record<string, ErrorMessage | null | undefined> = {}
  scrollToError = false

  static defaultProps = {
    TextInputComponent: TextInput,
    PasswordInputComponent: PasswordInput,
    DateInputComponent: DateInput,
    FileInputComponent: FileInput,
    SelectInputComponent: SelectInput,
    PhoneInputComponent: PhoneInput,
    CheckboxInputComponent: CheckboxInput,
  }

  scrollToFirstError() {
    const firstError = Object.values(this.errorRefs)[0]

    if (this.scrollToError && firstError?.errorDiv) {
      scrollToElement(firstError.errorDiv, {
        topBuffer: 200,
      })
      this.scrollToError = false
    }
  }

  componentDidMount() {
    this.scrollToFirstError()
  }

  componentDidUpdate() {
    this.scrollToFirstError()
  }

  onSubmit(e: React.SyntheticEvent) {
    e.preventDefault()

    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.object)
    }

    this.scrollToError = true
  }

  notifyChange(field: string, value: InputValue) {
    const { object } = this.props

    if (object[field] !== value) {
      this.props.onChange(field, value)
    }
  }

  notifyBlur(field: string, value: InputValue) {
    const { object, onBlur } = this.props

    if (object[field] !== value && onBlur) {
      onBlur(field, value)
    }
  }

  onChange(field: string, e: React.ChangeEvent<HTMLInputElement>) {
    this.notifyChange(field, e.target.value)
  }

  onDateChange(field: string, value: string) {
    this.notifyChange(field, value)
  }

  onBlur(field: string, e: React.FocusEvent<HTMLInputElement>) {
    this.notifyBlur(field, e.target.value)
  }

  onPhoneChange(field: string, number: string) {
    this.notifyChange(field, number)
  }

  onPhoneBlur(field: string, number: string) {
    this.notifyBlur(field, number)
  }

  registerErrorRef = (ref: ErrorMessage | null | undefined, key: string) => {
    if (ref === null) {
      delete this.errorRefs[key]
    } else {
      this.errorRefs[key] = ref
    }
  }

  fieldClassNames = (fieldClass?: string) =>
    classNames('amp-form-field', fieldClass)

  renderError(
    error: string | string[] = '',
    fieldName: string,
    type: 'block' | null | undefined = null,
  ): ReactNode | null | undefined {
    if (!error) {
      return
    }

    return (
      <ErrorMessage
        key={`${fieldName}-error`}
        type={type}
        ref={(el) => this.registerErrorRef(el, fieldName)}
      >
        {error}
      </ErrorMessage>
    )
  }

  renderFieldDecorations({ optional, decorations }: Options) {
    return (
      <div className="field-decorations">
        {optional ? (
          <span className="optional">
            {i18n.t('components.form.optional_field_decoration_text')}
          </span>
        ) : null}
        {decorations}
      </div>
    )
  }

  renderDisabledFieldAsText(
    name: string,
    value: string,
    label: Label,
    type: string,
    options: Record<string, any>,
  ) {
    let displayValue = value

    if (type === 'date' && typeof value === 'string') {
      displayValue = i18n.l('date.formats.long', value)
    }

    return (
      <div key={name} className="amp-form-field disabled">
        <span className="label">{label}</span>
        <span className="value">{displayValue}</span>
        {options.info && <div className="info">{options.info}</div>}
      </div>
    )
  }

  renderTextField(
    name: string,
    value: string,
    label: Label,
    type: 'text' | 'password' | 'email' | 'tel',
    error?: string,
    sublabel?: ReactNode,
    options: Options = {},
  ) {
    const className = classNames(
      decamelize(type, {
        separator: '-',
      }),
      decamelize(name, {
        separator: '-',
      }),
    )
    const { decorator } = options
    const { TextInputComponent } = this.props

    let textField = (
      <div className={className} key={name}>
        {this.renderFieldDecorations(options)}
        <TextInputComponent
          key={name}
          name={controlName(name)}
          type={type}
          label={label}
          sublabel={sublabel}
          value={value}
          hasError={error != null}
          onChange={this.onChange.bind(this, name)}
          onBlur={this.props.onBlur ? this.onBlur.bind(this, name) : undefined}
          {...options}
        />
      </div>
    )

    if (decorator) {
      textField = decorator(textField)
    }

    return (
      <div
        className={this.fieldClassNames(options.fieldClass)}
        key={name}
        data-testid={`${name}-field`}
      >
        {textField}
        {this.renderError(error, name)}
      </div>
    )
  }

  renderPasswordField(
    name: string,
    value: string,
    label: ReactNode,
    error?: string,
    options: Options = {},
  ): ReactNode {
    const { decorator } = options
    const { PasswordInputComponent } = this.props
    let passwordField = (
      <Password
        displayRequirementsAbove
        showCurrentPasswordField={false}
        showActions={false}
        label={label}
        passwordInputOverride={
          <PasswordInputComponent
            key={name}
            name={controlName(name)}
            value={value}
            hasError={error != null}
            onChange={this.onChange.bind(this, name)}
            onBlur={
              this.props.onBlur ? this.onBlur.bind(this, name) : undefined
            }
            {...options}
          />
        }
        passwordErrorOverride={this.renderError(error, name)}
      />
    )

    if (decorator) {
      passwordField = decorator(passwordField)
    }

    return (
      <div
        className={this.fieldClassNames(options.fieldClass)}
        key={name}
        data-testid={`${name}-field`}
      >
        {passwordField}
      </div>
    )
  }

  renderPhoneField(
    name: string,
    value: string,
    label: Label,
    error?: string,
    options: Options = {},
  ) {
    const { PhoneInputComponent } = this.props
    return (
      <div
        className={this.fieldClassNames(options.fieldClass)}
        key={name}
        data-testid={`${name}-field`}
      >
        {this.renderFieldDecorations(options)}
        <PhoneInputComponent
          key={name}
          label={label}
          value={value}
          hasError={error != null}
          onChange={this.onPhoneChange.bind(this, name)}
          onBlur={
            this.props.onBlur ? this.onPhoneBlur.bind(this, name) : undefined
          }
          {...options}
        />
        {this.renderError(error, name)}
      </div>
    )
  }

  renderFileField(
    name: string,
    value: null,
    label: Label,
    error?: string,
    options: Options = {},
  ) {
    const { maxsize, accept, decorator } = options
    const { FileInputComponent } = this.props
    let fileInput = (
      <FileInputComponent
        id={name}
        key={name}
        name={controlName(name)}
        maxsize={maxsize}
        accept={accept}
        disabled={options.disabled}
        onChange={this.notifyChange.bind(this, name)}
      >
        {label}
      </FileInputComponent>
    )

    if (decorator) {
      fileInput = decorator(fileInput)
    }

    return (
      <div
        className={this.fieldClassNames(options.fieldClass)}
        key={name}
        data-testid={`${name}-field`}
      >
        {this.renderFieldDecorations(options)}
        {fileInput}
        {this.renderError(error, name)}
      </div>
    )
  }

  renderDateField(
    name: string,
    value: string,
    label: Label,
    sublabel?: ReactNode,
    error?: string,
    options: Options = {},
  ) {
    const { DateInputComponent } = this.props
    let dateInput = (
      <DateInputComponent
        key={name}
        inputNamePrefix={controlName(name)}
        label={label}
        sublabel={sublabel}
        hasError={error != null}
        value={value}
        onChange={this.onDateChange.bind(this, name)}
        {...options}
      />
    )

    if (options.decorator) {
      dateInput = options.decorator(dateInput)
    }

    return (
      <div
        className={this.fieldClassNames(options.fieldClass)}
        key={name}
        data-testid={`${name}-field`}
      >
        {this.renderFieldDecorations(options)}
        {dateInput}
        {this.renderError(error, name)}
      </div>
    )
  }

  renderSelectField(
    name: string,
    value: string,
    label: Label,
    selectOptions: SelectInputOption[],
    error?: string,
    options: Options = {},
  ) {
    const { SelectInputComponent } = this.props
    let selectField = (
      <SelectInputComponent
        key={name}
        name={controlName(name)}
        label={label}
        hasError={error != null}
        value={value}
        onChange={this.onChange.bind(this, name)}
        options={selectOptions}
        {...options}
      />
    )

    if (options.decorator) {
      selectField = options.decorator(selectField)
    }

    return (
      <div
        className={this.fieldClassNames(options.fieldClass)}
        key={name}
        data-testid={`${name}-field`}
      >
        {this.renderFieldDecorations(options)}
        {selectField}
        {this.renderError(error, name)}
      </div>
    )
  }

  renderCheckboxField(
    name: string,
    value: boolean,
    label: Label,
    error?: string,
    options: Options = {},
  ) {
    const { CheckboxInputComponent } = this.props
    return (
      <div
        className={this.fieldClassNames(options.fieldClass)}
        key={name}
        data-testid={`${name}-cy`}
      >
        {this.renderFieldDecorations(options)}
        <CheckboxInputComponent
          key={name}
          label={label}
          hasError={error != null}
          checked={value}
          onChange={this.notifyChange.bind(this, name)}
          labelFirst={false}
          {...options}
        />
        {this.renderError(error, name)}
      </div>
    )
  }

  renderField(fieldName: string) {
    const fieldData = this.props.fields[fieldName]
    const {
      type,
      label = humanize(fieldName),
      sublabel,
      ...options
    } = fieldData
    const fieldValue = this.props.object[fieldName] || ''
    const error = this.props.errors[fieldName]
    const renderDisabledFieldAsText =
      options['renderDisabledFieldAsText'] !== false
    const selectInputOptions = options?.options

    if (options['disabled'] && renderDisabledFieldAsText) {
      return this.renderDisabledFieldAsText(
        fieldName,
        fieldValue,
        label,
        type,
        options,
      )
    }

    switch (type) {
      case 'text':
        return this.renderTextField(
          fieldName,
          fieldValue,
          label,
          'text',
          error,
          sublabel,
          options,
        )

      case 'password':
        return this.renderPasswordField(
          fieldName,
          fieldValue,
          label,
          error,
          options,
        )

      case 'email':
        return this.renderTextField(
          fieldName,
          fieldValue,
          label,
          'email',
          error,
          sublabel,
          options,
        )

      case 'date':
        return this.renderDateField(
          fieldName,
          fieldValue,
          label,
          sublabel,
          error,
          options,
        )

      case 'file':
        return this.renderFileField(
          fieldName,
          fieldValue,
          label,
          error,
          options,
        )

      case 'select':
        return this.renderSelectField(
          fieldName,
          fieldValue,
          label,
          selectInputOptions,
          error,
          options,
        )

      case 'phone':
        return this.renderPhoneField(
          fieldName,
          fieldValue,
          label,
          error,
          options,
        )

      case 'checkbox':
        return this.renderCheckboxField(
          fieldName,
          fieldValue,
          label,
          error,
          options,
        )

      default:
        throw new Error(`Unknown field type provided ${type}`)
    }
  }

  renderNonFieldErrors() {
    const { errors, fields } = this.props
    const nonFieldKeys = Object.keys(errors).filter(
      (eKey) =>
        !Object.hasOwnProperty.call(fields, eKey) ||
        (fields[eKey] && fields[eKey].disabled === true),
    )
    if (!nonFieldKeys.length) return

    return (
      <div className="error-messages">
        {nonFieldKeys.map((key, index) =>
          this.renderError(errors[key], index.toString(), 'block'),
        )}
      </div>
    )
  }

  renderActions() {
    const { actions, submit, onCancel } = this.props

    if (actions) return actions
    if (submit == null && !onCancel) return

    return (
      <div className="actions">
        {this.props.onCancel && (
          <div className="amp-form-cancel">
            <Button type="plain" onClick={this.props.onCancel}>
              {i18n.t('components.form.cancel_btn')}
            </Button>
          </div>
        )}
        {this.renderSubmit()}
      </div>
    )
  }

  renderSubmit(): ReactNode | null | undefined {
    const { submit, disabled } = this.props

    return (
      submit && (
        <div className="amp-form-submit">
          <Button
            id="form-submit"
            htmlType="submit"
            type={submitBtnType(submit)}
            disabled={disabled}
          >
            {submitBtnText(submit)}
          </Button>
        </div>
      )
    )
  }

  get orderedKeys(): FieldKey[] {
    const { fields, fieldOrder } = this.props

    if (fieldOrder) {
      return fieldOrder
    }

    return Object.keys(fields)
  }

  render() {
    const { fields, className } = this.props
    return (
      <form
        className={classNames('amp-form', className)}
        onSubmit={this.onSubmit.bind(this)}
      >
        {this.renderNonFieldErrors()}
        {this.orderedKeys.filter((k) => fields[k]).map(this.renderField, this)}
        {this.renderActions()}
      </form>
    )
  }
}
