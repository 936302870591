import { css } from 'styled-components'

import { color, device } from '~/styles/utils'

const containers = css`
  .confirm {
    background-color: ${color('ikonBlue')};

    .single-content {
      max-width: 550px;
      margin: auto;
    }

    .heading {
      text-align: center;
      margin: 32px 0;
    }

    .logo {
      width: 217px;
      height: 42px;
    }

    .success {
      line-height: 60px;
      border-bottom: 1px solid ${color('coolGreyLighter')};
      margin-bottom: 26px;

      @media ${device('notMobile')} {
        font-size: 25px;
      }
    }

    .controls {
      margin: 32px 0;
      text-align: center;
    }
  }

  .login {
    text-align: center;

    .amp-faq-list {
      margin-top: 40px;
    }
  }

  .reset-password {
    background-color: ${color('white')};
    color: ${color('ikonBlue')};
    text-align: left;
    max-width: 550px;
    margin: 40px auto 0;
    padding: 44px 73px;
    border: 2px solid ${color('coolGrey')};

    h1 {
      font-size: 36px;
      margin: 0;
    }
  }
`

export default containers
