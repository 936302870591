import { decamelizeKeys } from 'humps'

import type { Reservation } from '~/types'

export const serializeCreateReservation = ({
  addFriendsAndFamily,
  date,
  profileProductIds,
  resortId,
}: Reservation) =>
  decamelizeKeys({
    data: {
      addFriendsAndFamily,
      date,
      profileProductIds,
      resortId,
    },
  })

export const serializeUpdateReservation = ({
  addFriendsAndFamily,
  profileProductIds,
}: Reservation) =>
  decamelizeKeys({
    data: {
      addFriendsAndFamily,
      profileProductIds,
    },
  })
