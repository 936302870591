import { visit } from 'unist-util-visit'

// The official Markdown spec does not support headings inside task list items:
//
// Not supported:
//   - [x] ## This is a heading
//   - [ ] # This is another heading
//
// While this was (incorrectly) supported in react-markdown in the past, an update to
// the package has broken this behavior.
//
// This is still desired behavior on AMP, and it makes sense to use a custom plugin
// to continue to support this behavior.
export function supportTaskListItemHeadings() {
  return (tree) => {
    // Pass all list items in the tree into our `visitor` function
    visit(tree, 'listItem', visitor)

    function visitor(node) {
      const firstChild = node.children[0]

      // No adjustments need to be made to list items with no child elements
      if (!firstChild) return

      const isListItemImmediateChildAParagraph =
        firstChild.type === 'paragraph' &&
        firstChild.children[0].type === 'text'

      if (isListItemImmediateChildAParagraph) {
        const checkedTaskListItemRegex = /^\[x\] /
        const uncheckedTaskListItemRegex = /^\[ \] /
        const headingRegex = /^(#+) /

        // Set `checked` to true if the list item matches the task list syntax.
        // This inserts a checkbox input, which we override in the component to
        // display an icon.
        // We also remove the task list syntax so it doesn't display.
        if (firstChild.children[0].value.match(checkedTaskListItemRegex)) {
          node.checked = true
          firstChild.children[0].value = firstChild.children[0].value.replace(
            checkedTaskListItemRegex,
            '',
          )
        }

        // Set `checked` to false if the list item matches the task list syntax.
        // This inserts a checkbox input, which we override in the component to
        // display an icon.
        // We also remove the task list syntax so it doesn't display.
        if (firstChild.children[0].value.match(uncheckedTaskListItemRegex)) {
          node.checked = false
          firstChild.children[0].value = firstChild.children[0].value.replace(
            uncheckedTaskListItemRegex,
            '',
          )
        }

        // Now we check the remaining markdown. If this matches the heading syntax,
        // then we update it from being a paragraph to a heading, and assign the
        // correct heading depth (whether it is an h1, h2 etc.)
        if (firstChild.children[0].value.match(headingRegex)) {
          firstChild.type = 'heading'
          firstChild.depth =
            firstChild.children[0].value.match(headingRegex)[1].length
          firstChild.children[0].value = firstChild.children[0].value.replace(
            headingRegex,
            '',
          )
        }
      }
    }

    return
  }
}
