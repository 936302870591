import classNames from 'classnames'
import React from 'react'

import SwitchInput from '~/components/switch-input'

import { Label, Wrapper, Input } from './checkbox-input.styles'

import type { ReactNode } from 'react'

export type Props = {
  type?: 'switch'
  checked?: boolean
  onChange: (arg0: boolean) => void
  label?: ReactNode
  hasError?: boolean
  className?: string
  id?: string
  disabled?: boolean
  labelFirst?: boolean
}

export default function CheckboxInput({
  type,
  checked,
  onChange,
  label,
  hasError,
  className,
  id,
  disabled,
  labelFirst = false,
}: Props) {
  return (
    <Wrapper
      htmlFor={id}
      className={classNames(className, 'amp-checkbox-input')}
    >
      {labelFirst && label && (
        <Label $disabled={disabled} $hasError={hasError}>
          {label}
        </Label>
      )}
      {type === 'switch' ? (
        <SwitchInput
          className={classNames('switch-input', {
            checked,
          })}
          disabled={disabled}
          checked={checked}
          onChange={(checked) => !disabled && onChange(checked)}
        />
      ) : (
        <Input
          className={classNames('input', className)}
          type="checkbox"
          disabled={disabled}
          checked={checked}
          onChange={(e) => !disabled && onChange(e.target.checked)}
          id={id}
          $hasError={hasError}
        />
      )}
      {!labelFirst && label && (
        <Label $disabled={disabled} $hasError={hasError}>
          {label}
        </Label>
      )}
    </Wrapper>
  )
}
