/**
 * Reducer for state related to the UI of the profile settings.
 */

import { combineReducers } from 'redux'

import {
  PasswordState,
  PASSWORD_UPDATE_STATUS,
  TOGGLE_EDITING_PASSWORD,
} from '../../actions/ui/profile'

import type { AppActions } from '../../actions/types'
import type { ProfileUIActions } from '../../actions/ui/profile'

export function passwordStatus(state = '', action: ProfileUIActions) {
  switch (action.type) {
    case PASSWORD_UPDATE_STATUS:
      return action.state

    default:
      return state
  }
}

export function editingPassword(state = false, action: AppActions) {
  switch (action.type) {
    case TOGGLE_EDITING_PASSWORD:
      return !state

    case PASSWORD_UPDATE_STATUS:
      return action.state === PasswordState.SUCCESS ? false : state

    default:
      return state
  }
}

export default combineReducers<any>({
  editingPassword,
  passwordStatus,
})
