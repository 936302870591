import React from 'react'

import { Label, ReactInternationalPhoneInput } from './phone-input.styles'

import 'react-international-phone/style.css'

import type { ReactNode } from 'react'

export type Props = {
  value: string
  onChange: (arg0: string) => void
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  label?: ReactNode
  hasError?: boolean
  id?: string
  className?: string
}

export default function PhoneInput({
  hasError,
  disabled,
  value,
  onChange,
  onBlur,
  id = 'phone',
  label,
  className,
}: Props) {
  return (
    <div className={className}>
      {label && (
        <Label className="label" htmlFor={id}>
          {label}
        </Label>
      )}
      <ReactInternationalPhoneInput
        $hasError={hasError}
        defaultCountry="us"
        disabled={disabled}
        value={value}
        onChange={(_, { inputValue }) => onChange(inputValue)}
        onBlur={onBlur}
        inputProps={{ id }}
      />
    </div>
  )
}
