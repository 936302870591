/**
 * Component to handle date entry.
 */
import classNames from 'classnames'
import React, { useEffect, useRef, useState, ReactNode } from 'react'

import './date-input.scss'
import Button from '~/components/button'
import TextInput from '~/components/text-input'
import TextInputSublabel from '~/components/text-input-sublabel'
import { i18n } from '~/i18n'
import { strToStrDateObj, strDateObjToStr } from '~/utils/date-str'

type DateObj = {
  day: string
  month: string
  year: string
}

const restrictors = {
  day: (newValue, previous) => {
    newValue = newValue.substr(-2)
    const num = parseInt(newValue, 10)
    if (num > 31) {
      return previous
    }
    return newValue
  },
  month: (newValue, previous) => {
    newValue = newValue.substr(-2)
    const num = parseInt(newValue, 10)
    if (num > 12) {
      return previous
    }
    return newValue
  },
  year: (newValue: string, previous: string) => {
    newValue = newValue.substr(0, 4)
    const num = parseInt(newValue, 10)
    if (num > 9999) {
      return previous
    }
    return newValue
  },
}

function otherFieldsChanged(changedField, previousDateObj, newDateObj) {
  return Object.keys(previousDateObj).some((field) => {
    if (field === changedField) {
      return false
    }
    const previous = previousDateObj[field]
    const newValue = newDateObj[field]
    return parseInt(previous, 10) !== parseInt(newValue)
  })
}

export function determineChange(
  currentDateObj: DateObj,
  field: string,
  value: string,
): [DateObj, string] {
  const cleanValue = value.replace(/\D/g, '')
  const restrictedValue = restrictors[field](cleanValue, currentDateObj[field])
  let newDateStr = strDateObjToStr({
    ...currentDateObj,
    [field]: restrictedValue,
  })
  const newDateObj = { ...currentDateObj, [field]: restrictedValue }
  if (otherFieldsChanged(field, currentDateObj, strToStrDateObj(newDateStr))) {
    // Other fields have changed as a result of this field. This means that
    // this change is invalid so set the result to an empty string.
    newDateStr = ''
  }
  if (field === 'year' && restrictedValue.toString().length !== 4) {
    // Prevent year changes being parsed into an unexpected date when we don't
    // have a 4 digit year.
    newDateStr = ''
  }
  return [newDateObj, newDateStr]
}

export type Props = {
  className?: string
  clearable?: boolean
  value?: string
  disabled?: boolean
  inputNamePrefix: string
  onChange: (newDate: string) => void
  hasError?: boolean
  label?: ReactNode
  sublabel?: ReactNode
  setValidationError?: (...args: Array<any>) => any
}

const DateInput: React.FC<Props> = ({
  className,
  clearable,
  value = '',
  disabled,
  inputNamePrefix,
  onChange,
  hasError,
  label,
  sublabel,
  setValidationError,
}) => {
  const dayRef = useRef<HTMLInputElement>()
  const monthRef = useRef<HTMLInputElement>()
  const yearRef = useRef<HTMLInputElement>()

  const [inputValues, setInputValues] = useState({
    day: strToStrDateObj(value).day?.toString() || '',
    month: strToStrDateObj(value).month?.toString() || '',
    year: strToStrDateObj(value).year?.toString() || '',
  })

  useEffect(() => {
    // Update the state if we have a valid dateObj so that we don't
    // remove all the input that that has been provided.
    // It should be sufficient to check the presence of a single field.
    // If the state is valid (we have a date) and the new value is empty it
    // needs to be cleared.
    const newDateValueMatchesInputValues =
      strDateObjToStr(inputValues) === value

    if (!newDateValueMatchesInputValues) {
      setInputValues({
        day: strToStrDateObj(value).day?.toString() || '',
        month: strToStrDateObj(value).month?.toString() || '',
        year: strToStrDateObj(value).year?.toString() || '',
      })
    }
  }, [value])

  const transferFocus = (field: string, newDateObj: DateObj) => {
    const isDayOrMonth = field === 'day' || field === 'month'
    const fieldHasChanged = newDateObj[field] !== inputValues[field]
    const fieldIsAtMaxLength = newDateObj[field].length === 2

    if (isDayOrMonth && fieldHasChanged && fieldIsAtMaxLength) {
      const ref = field === 'month' ? dayRef.current : yearRef.current

      if (ref) {
        ref.focus()
        ref.setSelectionRange(0, ref.value.length)
      }
    }
  }

  const getErrorMessage = (newDateObj: DateObj) => {
    const errors = []
    if (newDateObj.day.length != 2) errors.push('missing day')
    if (newDateObj.month.length != 2) errors.push('missing month')
    if (newDateObj.year.length != 4) errors.push('missing year')
    return errors.join(', ')
  }

  const handleChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value: newValue } = event.target
    const [newDateObj, dateStr] = determineChange(inputValues, field, newValue)
    transferFocus(field, newDateObj)

    if (setValidationError) setValidationError(getErrorMessage(newDateObj))

    setInputValues((inputValues) => ({
      ...inputValues,
      [field]: newDateObj[field],
    }))

    if (dateStr !== '' && dateStr !== value) onChange(dateStr)
  }

  const renderLabel = () => {
    if (label) {
      return (
        <label className="label" htmlFor={`${inputNamePrefix}-month`}>
          {label}
        </label>
      )
    }
  }

  const padInputWithZero = (field: string) => {
    if (inputValues[field].length === 1) {
      setInputValues((inputValues) => ({
        ...inputValues,
        [field]: `0${inputValues[field]}`,
      }))
    }
  }

  const { year, month, day } = inputValues
  const fieldClass = classNames(
    'amp-date-input',
    {
      'has-error': hasError,
      disabled: disabled,
    },
    className,
  )

  return (
    <fieldset className={fieldClass}>
      {renderLabel()}
      <TextInputSublabel>{sublabel}</TextInputSublabel>
      <div className="inputs-wrapper">
        <input
          data-testid={inputNamePrefix}
          type="hidden"
          name={inputNamePrefix}
          value={value}
        />
        <TextInput
          id={`${inputNamePrefix}-month`}
          className={`${inputNamePrefix}-month`}
          inputRef={monthRef}
          name={`${inputNamePrefix}-month`}
          placeholder={i18n.t('components.date_input.month_placeholder')}
          value={month}
          disabled={disabled}
          hasError={hasError}
          type="tel"
          maxLength={2}
          minLength={1}
          onChange={handleChange.bind(null, 'month')}
          onBlur={() => padInputWithZero('month')}
        />
        <TextInput
          id={`${inputNamePrefix}-day`}
          className={`${inputNamePrefix}-day`}
          inputRef={dayRef}
          name={`${inputNamePrefix}-day`}
          placeholder={i18n.t('components.date_input.day_placeholder')}
          value={day}
          disabled={disabled}
          hasError={hasError}
          type="tel"
          maxLength={2}
          minLength={1}
          onChange={handleChange.bind(null, 'day')}
          onBlur={() => padInputWithZero('day')}
        />
        <TextInput
          id={`${inputNamePrefix}-year`}
          className={`${inputNamePrefix}-year`}
          inputRef={yearRef}
          name={`${inputNamePrefix}-year`}
          placeholder={i18n.t('components.date_input.year_placeholder')}
          value={year}
          disabled={disabled}
          hasError={hasError}
          type="tel"
          maxLength={4}
          minLength={4}
          onChange={handleChange.bind(null, 'year')}
        />
        {clearable && (
          <Button
            glyph="close"
            type="plain"
            title={i18n.t('components.date_input.clear_btn.title')}
            onClick={() => onChange(null)}
          />
        )}
      </div>
    </fieldset>
  )
}

export default DateInput
