type Face = Array<string>

type CloudinaryData = {
  public_id: string
  version: string
  width: number
  height: number
  created_at: string
  faces: Array<Face>
}
/**
 * cloudinaryDeserializer extracts the information we are interested
 * in from a upload to cloudinary.
 * @param  {Object} data The parsed json payload returned from the upload.
 * @return {Object}      The converted structure.
 */

export default function cloudinaryDeserializer(data: CloudinaryData) {
  const result = {
    publicId: data.public_id,
    version: data.version.toString(),
    width: data.width,
    height: data.height,
    faces: '',
    uploadedAt: data.created_at,
  }

  if (data.faces && data.faces.length > 0) {
    result.faces = data.faces.map((face) => face.join(',')).join('|')
  }

  return result
}
