import HTTPError from '~/utils/http-error'

import { getCustomerAPI } from './'

import type { API$ReservationAvailability, API$Reservation, API } from '~/types'

type ReservationAvailabilityResponse = {
  data: API$ReservationAvailability[]
  errors: []
}

type ReservationsResponse = {
  data: API$Reservation[]
  errors: []
}

export async function getReservationAvailability(
  resortId: number,
  api: API = getCustomerAPI(),
): Promise<ReservationAvailabilityResponse> {
  const res = await api.getReservationAvailability(resortId)

  if (res.ok) return res.json()

  throw new HTTPError(
    `failed: getReservationAvailability`,
    res,
    await res.text(),
  )
}

export async function getReservations(
  api: API = getCustomerAPI(),
): Promise<ReservationsResponse> {
  const res = await api.getReservations()

  if (res.ok) return res.json()

  throw new HTTPError(`failed: getReservations`, res, await res.text())
}

export async function getCartReservations(
  api: API = getCustomerAPI(),
): Promise<ReservationsResponse> {
  const res = await api.getReservationCart()

  if (res.ok) return res.json()

  throw new HTTPError(`failed: getCartReservations`, res, await res.text())
}
