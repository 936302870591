import styled from 'styled-components'

import { color, font } from '~/styles/utils'

import TextInputV1 from './text-input.component'

const TextInputV2 = styled(TextInputV1)<{
  hasError: boolean
}>`
  && {
    div.amp-text-input-label {
      ${font('demi')};
      font-size: 14px;
      line-height: 28px;
    }

    .text-wrapper {
      border-color: ${(props) =>
        props.hasError ? color('red') : color('lightestGrey')};
    }

    input {
      background-color: ${color('lightestGrey')};
    }
  }
`

export default TextInputV2
