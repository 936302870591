import Switch from 'react-ios-switch'
import styled, { css } from 'styled-components'

import colors from '~/styles/theme/colors'
import { color, font } from '~/styles/utils'

class UnstyledSwitch extends Switch {
  getWidth() {
    return 67
  }
}

export default styled(UnstyledSwitch).attrs(() => ({
  onColor: colors.blueCreek,
  offColor: colors.mediumGrey,
  'data-testid': 'switch-input',
}))<{ checked?: boolean }>`
  color: ${color('white')};

  &:after {
    ${font('heading')};
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    right: 8px;
    font-size: 10px;
  }

  ${({ checked }) =>
    checked
      ? css`
          &:after {
            font-family: 'icomoon' !important;
            content: '\\e918';
            left: 15px;
          }
        `
      : css`
          &:after {
            font-family: 'icomoon' !important;
            content: '\\e90d';
            right: 15px;
          }
        `};
`
