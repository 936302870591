type In = {
  termsAcceptedAt: number
}

type Out = {
  data: {
    terms_accepted_at: number
  }
}

export default function serializeOrder({ termsAcceptedAt }: In): Out {
  return {
    data: {
      terms_accepted_at: termsAcceptedAt,
    },
  }
}
