import HTTPError from '~/utils/http-error'

import serializeCloudinaryPhoto from '../../serializers/cloudinary-photo'
import { authedFetch } from '../../utils/fetch'

import type { CloudinaryPhoto } from '~/types'

export async function requestSavePhoto(
  photo: CloudinaryPhoto,
  profileId: string,
  redirectUrl?: string,
) {
  const body = serializeCloudinaryPhoto(photo)

  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify(body),
  }

  const path = `/api/v2/profiles/${profileId}/photo`
  const res = await authedFetch(path, options, redirectUrl)

  if (res.ok) return res

  throw new HTTPError(`failed: PATCH ${path}`, res, await res.text())
}
