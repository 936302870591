export type UpgradeUIActions =
  | {
      type: 'START_ADD_UPGRADES'
    }
  | {
      type: 'STOP_ADD_UPGRADES'
    }
  | {
      type: 'SHOW_UPGRADES_ADDED'
    }
  | {
      type: 'HIDE_UPGRADES_ADDED'
    }

export const START_ADD_UPGRADES = 'START_ADD_UPGRADES'

export function startAddUpgrades() {
  return {
    type: START_ADD_UPGRADES,
  }
}

export const STOP_ADD_UPGRADES = 'STOP_ADD_UPGRADES'

export function stopAddUpgrades() {
  return {
    type: STOP_ADD_UPGRADES,
  }
}

export const SHOW_UPGRADES_ADDED = 'SHOW_UPGRADES_ADDED'

export function showUpgradesAdded() {
  return {
    type: SHOW_UPGRADES_ADDED,
  }
}

export const HIDE_UPGRADES_ADDED = 'HIDE_UPGRADES_ADDED'

export function hideUpgradesAdded() {
  return {
    type: HIDE_UPGRADES_ADDED,
  }
}
