import React from 'react'

import AnyLink from '~/components/any-link'

import {
  DefaultButton,
  Content,
  PlainButton,
  UnstyledButton,
  OutlineButton,
} from './button.styles'

import type { Props } from './button.types'

const Button = (props: Props) => {
  const { className, disabled, htmlType, to, type, onClick, ...otherProps } =
    props

  let Wrapper = DefaultButton

  if (type === 'plain') Wrapper = PlainButton
  else if (type === 'unstyled') Wrapper = UnstyledButton
  else if (type === 'outline') Wrapper = OutlineButton

  return (
    <Wrapper
      as={to && !disabled ? AnyLink : undefined}
      className={className}
      data-testid="button"
      disabled={disabled}
      to={to}
      type={htmlType || 'button'}
      onClick={onClick}
      {...otherProps}
    >
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

export default Button
