import { camelizeKeys } from 'humps'

import { authedFetch } from '~/utils/fetch'
import HTTPError from '~/utils/http-error'

const PATH = '/api/v2/product-catalog'

export function requestCatalog(currency: string) {
  return authedFetch(`${PATH}?currency=${currency}&only_available=true`).then(
    (resp) => {
      if (resp.ok) {
        return resp.json().then((productCatalog) => {
          //Temporary remapping fix marketing issues
          const camelizedCatalog = camelizeKeys(productCatalog)
          return remap(camelizedCatalog)
        })
      } else {
        return Promise.reject(
          new HTTPError('load product catalog unknown error', resp, ''),
        )
      }
    },
  )
}

function remap(productCatalog) {
  if (!productCatalog.data) {
    return productCatalog
  }

  const data = productCatalog.data.map((product) => {
    return {
      ...product,
      variants: product.variants.map((variant) => {
        const ageRangeObject = variant.ageRangeObject
        delete variant.ageRangeObject

        return {
          ...variant,
          displayAgeRange: variant.ageRange,
          ageRange: ageRangeObject,
        }
      }),
    }
  })

  return {
    data,
  }
}
