import IkonWebTheme from '@alterra-mountain-company/alterra-design-system/dist/tokens/ikonWeb'

import border from './borders'
import color from './colors'
import device from './devices'
import font from './fonts'
import layer from './layers'
import variables from './variables'

const theme = {
  border,
  color,
  device,
  font,
  layer,
  variables,
}

export type Theme = keyof typeof theme
export type Devices = keyof typeof device
export type Colors = keyof typeof color
export type Fonts = keyof typeof font
export type Borders = keyof typeof border
export type Layers = keyof typeof layer
export type Variables = keyof typeof variables

export type TextAlign =
  | 'center'
  | 'left'
  | 'right'
  | 'justify'
  | 'initial'
  | 'inherit'

export default { ...IkonWebTheme, _legacy: theme }
