import type { ValueOf } from '~/types'
/**
 * Defines actions and action creators related to the profile UI.
 */
export const PasswordState = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
}

export type ProfileUIActions = {
  type: 'PASSWORD_UPDATE_STATUS'
  state: ValueOf<typeof PasswordState>
}

export const PASSWORD_UPDATE_STATUS = 'PASSWORD_UPDATE_STATUS'

export function updatePasswordStatus(
  state: ValueOf<typeof PasswordState>,
): ProfileUIActions {
  return {
    type: PASSWORD_UPDATE_STATUS,
    state,
  }
}

export const TOGGLE_EDITING_PASSWORD = 'TOGGLE_EDITING_PASSWORD'

export const toggleEditingPassword = () => ({
  type: TOGGLE_EDITING_PASSWORD,
})
