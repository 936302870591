import keyBy from 'lodash/keyBy'

import { GROUP_LOADED, GROUP_LOADING } from '~/actions/group'
import { PROFILE_UPDATE } from '~/actions/profile'

import type { GroupMember } from '~/types'

const DEFAULT_STATE = {
  loaded: false,
  loading: false,
  data: [],
  byId: {},
}

export type GroupMemberState = {
  loaded: boolean
  loading: boolean
  data: GroupMember[]
  byId: Record<string, GroupMember>
}

function updateGroupMember(groupMember, profile) {
  return {
    ...groupMember,
    ...profile,
    cloudinary: { ...groupMember.cloudinary, ...profile.cloudinary },
  }
}

function updateGroupMembers(groupMembers, profile): GroupMember[] {
  return groupMembers.data.map((member) =>
    member.id === profile.id ? updateGroupMember(member, profile) : member,
  )
}

const getById = (data: GroupMember[]): Record<string, GroupMember> => {
  return keyBy(data, 'id')
}

export default function groupMembers(
  state: GroupMemberState = DEFAULT_STATE,
  action: Record<string, any>,
) {
  switch (action.type) {
    case GROUP_LOADING:
      return { ...state, loading: true }

    case GROUP_LOADED:
      return {
        byId: getById(action.data),
        data: action.data,
        loading: false,
        loaded: true,
      }

    case PROFILE_UPDATE:
      // eslint-disable-next-line no-case-declarations
      const data = updateGroupMembers(state, action.profile)
      return { ...state, data, byId: getById(data) }

    default:
      return state
  }
}
