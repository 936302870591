import styled from 'styled-components'

import { color, font } from '~/styles/utils'

import SelectInputV1 from './select-input.component'

const SelectInputV2 = styled(SelectInputV1)`
  && {
    div.label {
      ${font('demi')};
      font-size: 14px;
      line-height: 28px;
    }

    div.select-wrapper {
      border-color: ${color('lightestGrey')};
    }

    && select {
      background-color: ${color('lightestGrey')};
    }
  }
`

export default SelectInputV2
