import getCustomerAPI from '~/utils/api/get-customer-api'
import HTTPError from '~/utils/http-error'
import { reportGroupedError } from '~/utils/logger'

export async function createMinorConsents(profileIds: string[]) {
  const api = getCustomerAPI()

  try {
    const response = await api.createMinorConsents(profileIds)

    if (!response.ok) {
      throw new HTTPError(
        `failed: createMinorConsents`,
        response,
        await response.text(),
      )
    }
  } catch (error) {
    reportGroupedError('createMinorConsents', error)
  }
}
