import { COUNTRIES_AND_STATES, COUNTRY_NAMES_TO_COUNTRY_CODES } from '../config'

import type { CountriesAndStates } from '~/types'

export function countriesAndStates(): CountriesAndStates {
  return COUNTRIES_AND_STATES
}

export const countryStringToCode = (countryString: string) =>
  (COUNTRY_NAMES_TO_COUNTRY_CODES || {})[countryString]
