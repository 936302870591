/**
 * Defines actions and action creators related to the cart UI.
 */
import type { ValueOf } from '~/types'

export const SigningWaiverState = {
  PROCESSING: 'PROCESSING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
}

export type CartStatus = 'PROCESSING' | 'FAILED' | ''

export type WaiverStates = ValueOf<typeof SigningWaiverState> | ''

export type CartUIActions =
  | {
      type: 'UPDATE_CART_STATUS'
      state: CartStatus
    }
  | {
      type: 'UPDATE_CART_FAILURE_MESSAGE'
      state: string
    }
  | {
      type: 'SET_WAIVER_SIGNED_DATE_STATUS'
      state: WaiverStates
    }
  | {
      type: 'START_ADD_UPGRADES'
    }
  | {
      type: 'STOP_ADD_UPGRADES'
    }
  | {
      type: 'START_UPDATE_PASS_ASSIGNMENT'
    }
  | {
      type: 'STOP_UPDATE_PASS_ASSIGNMENT'
    }

export const UPDATE_CART_STATUS = 'UPDATE_CART_STATUS'

export function updateCartStatus(state: CartStatus): CartUIActions {
  return {
    type: UPDATE_CART_STATUS,
    state,
  }
}

export const UPDATE_CART_FAILURE_MESSAGE = 'UPDATE_CART_FAILURE_MESSAGE'

export function updateCartFailureMessage(state = ''): CartUIActions {
  return {
    type: UPDATE_CART_FAILURE_MESSAGE,
    state,
  }
}

export const SET_WAIVER_SIGNED_DATE_STATUS = 'SET_WAIVER_SIGNED_DATE_STATUS'

export function signWaiverStatus(state: WaiverStates): CartUIActions {
  return {
    type: SET_WAIVER_SIGNED_DATE_STATUS,
    state,
  }
}

export const START_UPDATE_PROMO_CODE = 'START_UPDATE_PROMO_CODE'
export function startUpdatePromoCode() {
  return {
    type: START_UPDATE_PROMO_CODE,
  }
}

export const STOP_UPDATE_PROMO_CODE = 'STOP_UPDATE_PROMO_CODE'
export function stopUpdatePromoCode() {
  return {
    type: STOP_UPDATE_PROMO_CODE,
  }
}

export const START_UPDATE_PASS_ASSIGNMENT = 'START_UPDATE_PASS_ASSIGNMENT'
export function startUpdatePassAssignment() {
  return {
    type: START_UPDATE_PASS_ASSIGNMENT,
  }
}

export const STOP_UPDATE_PASS_ASSIGNMENT = 'STOP_UPDATE_PASS_ASSIGNMENT'
export function stopUpdatePassAssignment() {
  return {
    type: STOP_UPDATE_PASS_ASSIGNMENT,
  }
}
