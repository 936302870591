export const SET_PRODUCT_NAME_FILTER_VALUE =
  'SET_PRODUCT_NAME_FILTER_VALUE' as const
export const SET_PRODUCT_SEASON_ID_FILTER_VALUE =
  'SET_PRODUCT_SEASON_ID_FILTER_VALUE' as const

export type FilterUIActions =
  | {
      type: 'SET_PRODUCT_NAME_FILTER_VALUE'
      value: string
    }
  | {
      type: 'SET_PRODUCT_SEASON_ID_FILTER_VALUE'
      value: number
    }

export function setProductNameFilterValue(value: string) {
  return {
    type: SET_PRODUCT_NAME_FILTER_VALUE,
    value,
  }
}

export function setProductSeasonIdFilterValue(value: number) {
  return {
    type: SET_PRODUCT_SEASON_ID_FILTER_VALUE,
    value,
  }
}
