/**
 * Defines actions and action creators related to the credit card in the cart.
 */
import deserializeCart from '~/deserializers/cart'
import getCustomerAPI from '~/utils/api/get-customer-api'

import { updateCart } from './index'
import { reportGroupedError } from '../../utils/logger'

import type { API, PaymentData } from '~/types'

type Dispatch = (...args: Array<any>) => any

export function setCartCreditCard({
  api = getCustomerAPI(),
  onResponseErrors,
  paymentData,
}: {
  api?: API
  onResponseErrors: (errors: Record<string, any>) => any
  paymentData: PaymentData
}) {
  return async (dispatch: Dispatch) => {
    try {
      const { creditCard, saveCardOnFile } = paymentData
      const resp = await api.setCartCreditCard(creditCard, !!saveCardOnFile)
      const { data, errors } = await resp.json()
      if (resp.ok) {
        const cart = deserializeCart(data)
        dispatch(updateCart(cart))
      } else {
        onResponseErrors(errors)
      }
    } catch (error) {
      reportGroupedError('setCartCreditCard', error)

      throw error
    }
  }
}
